import CreatorGroupMembership, {
  MembershipStatus,
} from '@/types/creatorGroupMembership';
import creatorGroupsFetchers from '@/api/fetchers/creatorGroups';
import useDataQuery from '@/hooks/use-data-query';

function getMembershipStatus(
  membership: CreatorGroupMembership
): MembershipStatus {
  let status: MembershipStatus = 'active';
  if (membership.removedAt) {
    status = 'removed';
  } else if (membership.pending === true) {
    status = 'pending';
  } else if (
    membership.profile === undefined ||
    membership.profile.isActive === false
  ) {
    status = 'deactivated';
  }

  return status;
}

type UseCreatorGroupsLabelsQueryParams = {
  creatorGroupId?: string;
  labelIds?: string[];
  q?: string | null;
  page: number;
  unlabeled?: boolean;
  refetchOnWindowFocus?: boolean;
};

const modelHelpers = (membership: CreatorGroupMembership) => ({
  status: getMembershipStatus(membership),
});

function useCreatorGroupsMembershipsQuery({
  creatorGroupId,
  labelIds,
  q,
  page,
  refetchOnWindowFocus = true,
}: UseCreatorGroupsLabelsQueryParams) {
  return useDataQuery({
    queryKey: ['creatorGroupsMemberships', creatorGroupId, labelIds, q, page],
    queryFn: creatorGroupsFetchers.memberships,
    enabled: !!creatorGroupId,
    meta: {creatorGroupId, labelIds, q, page},
    refetchOnWindowFocus,
    select: (response: any) => {
      return {
        ...response,
        data: response.data.map((membership: CreatorGroupMembership) => ({
          ...membership,
          ...modelHelpers(membership),
        })),
      };
    },
  });
}

export default useCreatorGroupsMembershipsQuery;
