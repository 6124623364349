import type {MRT_Row} from 'material-react-table';
import React from 'react';
import {Box} from '@mui/material';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import VetCreatorButton from './VetCreator/VetCreatorButton';

function VetCreatorCell({
  row,
  onVetButtonClick,
  vetButtonRef,
  vetCreatorButtonClass,
  activeVetPopoverClass,
  isVetPopoverActive,
}: {
  row: MRT_Row<CreatorGroupMembership>;
  onVetButtonClick: (
    membership: CreatorGroupMembership,
    event: React.MouseEvent<Element, MouseEvent>
  ) => void;
  vetButtonRef: React.RefObject<HTMLDivElement>;
  vetCreatorButtonClass: string;
  activeVetPopoverClass: string;
  isVetPopoverActive: boolean;
}) {
  return (
    <Box sx={{position: 'relative'}} ref={vetButtonRef}>
      <VetCreatorButton
        onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
          onVetButtonClick(row.original, event)
        }
        className={`${vetCreatorButtonClass} ${
          isVetPopoverActive ? activeVetPopoverClass : ''
        }`}
      />
    </Box>
  );
}

export default VetCreatorCell;
