import React, {useEffect, useState} from 'react';
import {Avatar, designSystemToken} from '@lightricks/react-design-system';
import checkImageValid from '@/utils/checkImageValid';
import styles from './CreatorAvatar.module.scss';

type CreatorAvatarProps = {
  profileImageUrl?: string;
};

function CreatorAvatar({profileImageUrl}: CreatorAvatarProps) {
  const [imageUrlValid, setImageUrlValid] = useState(true);
  useEffect(() => {
    checkImageValid(profileImageUrl || '', setImageUrlValid);
  }, [profileImageUrl]);

  return (
    <div className={styles.creatorAvatarContainer}>
      <Avatar
        className={styles.creatorAvatar}
        size="2xl"
        src={imageUrlValid ? profileImageUrl : ''}
        type={profileImageUrl && imageUrlValid ? 'picture' : 'placeholder'}
        variant="circular"
        background={designSystemToken('semantic.bg.tertiary')}
        data-testid="avatar"
      />
    </div>
  );
}

export default CreatorAvatar;
