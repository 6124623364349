import {enableMapSet} from 'immer';
import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import MainRoutes from '@/routes/main';
import PublicAccessRoutes from '@/routes/public-access';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import pageViewEvents from '@/lib/marketing-events/pageViewEvents';
import PoppaysLoader from '@/components/PoppaysLoader';
import EmberIframe from '@/components/ember-iframe';
import FlashMessagesService from '@/components/flash-messages-service';
import ModalContactSales from '@/components/modal-contact-sales';
import ModalLeverLimit from '@/components/modal-lever-limit';
import ModalSubscriptionWrapper from '@/components/modal-subscription-wrapper';
import ModalTermsOfService from '@/components/modal-terms-of-service';
import NewCampaignWizardModal from '@/components/new-campaign-wizard-modal';
import useAnalyticsLtxTeamIdentifier from '@/hooks/use-analytics-ltx-team-identifier';
import useCampaignCreationScreenChangedEvent from '@/hooks/use-campaign-creation-screen-changed-event/useCampaignCreationScreenChangeEvent';
import useDeltaEventScreenChanged from '@/hooks/use-delta-event-screen-changed';
import useFullStoryUser from '@/hooks/use-fullstory-user';
import usePendo from '@/hooks/use-pendo';
import useRevalidateSession from '@/hooks/use-revalidate-session';
import useSentryUser from '@/hooks/use-sentry-user';
import useSetBrandIdFromLoggedInDashboardUser from '@/hooks/use-set-brand-id-from-logged-in-dashboard-user';
import useSetLaunchDarklyContext from '@/hooks/use-set-launch-darkly-context';
import useSyncDeltaBaseAttributes from '@/hooks/use-sync-delta-base-attributes';
import useToken from '@/hooks/use-token';
import useZendeskScript from '@/hooks/use-zendesk-script';
import AppProviders from '@/AppProviders';
import '@/index.scss';
import './app.scss';

EmberIframe.listen('changePageTitle', (title: string) => {
  document.title = title;
});

function App() {
  enableMapSet();
  usePendo();
  useSentryUser();
  useFullStoryUser();
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    AnalyticsService.dispatchPageView(pagePath);
    pageViewEvents();
  }, [location]);

  const isRevalidatingSession = useRevalidateSession();
  const {flags, launchDarklyInitialized} = useSetLaunchDarklyContext();
  const {showZendeskScript} = flags;
  useSetBrandIdFromLoggedInDashboardUser();
  useZendeskScript('320c4ae8-8ac1-467d-9ac3-ea18ec237353', showZendeskScript);

  if (isRevalidatingSession || !launchDarklyInitialized) {
    return <PoppaysLoader />;
  }

  return <MainRoutes flags={flags} />;
}

function AppRoutes() {
  const {isAuthenticated} = useToken(true, true);
  useSyncDeltaBaseAttributes(isAuthenticated);
  useAnalyticsLtxTeamIdentifier(isAuthenticated);
  useDeltaEventScreenChanged();
  useCampaignCreationScreenChangedEvent();

  return (
    <Routes>
      {PublicAccessRoutes()}
      <Route path="/*" element={<App />} />
    </Routes>
  );
}

function WrappedApp() {
  // all of various providers should be wrapping the <App /> component in here
  return (
    <AppProviders>
      <>
        <Router>
          <Routes>
            <Route path="/*" element={<AppRoutes />} />
          </Routes>
          <ModalLeverLimit />
          <ModalContactSales />
          <ModalSubscriptionWrapper />
          <ModalTermsOfService />
          <NewCampaignWizardModal />
        </Router>
        <FlashMessagesService />
      </>
    </AppProviders>
  );
}

export default WrappedApp;
