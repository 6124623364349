import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {designSystemToken, Tooltip} from '@lightricks/react-design-system';
import getEngagementRateString from '@/utils/getEngagementRateString';
import getFollowersCountString from '@/utils/getFollowersCountString';
import translate from '@/utils/translate';
import {getSupportedNetworks} from '@/utils/utils';
import {SocialNetworkInfo} from '@/types/socials';
import styles from '@/components/creator-card/CreatorSocialsIcons.module.scss';
import SocialStat from '@/components/creator-card/SocialStat';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';

type CreatorSocialsIconsProps = {
  socialNetworks: SocialNetworkInfo[];
  selectedPlatformProvider?: string;
  onClickSocialNetwork?: (networkProvider: string) => void;
};

function CreatorSocialsIcons({
  socialNetworks,
  selectedPlatformProvider,
  onClickSocialNetwork,
}: CreatorSocialsIconsProps) {
  const SOCIAL_NETWORK_ICON_SIZE = 24;
  const sortedSocialNetworks = useMemo(() => {
    const socialNetworkOrder = getSupportedNetworks();
    return socialNetworks.slice().sort((a, b) => {
      return (
        socialNetworkOrder.indexOf(a.provider) -
        socialNetworkOrder.indexOf(b.provider)
      );
    });
  }, [socialNetworks]);

  return (
    <div className={styles.socialNetworksContainer}>
      {sortedSocialNetworks.map((network: SocialNetworkInfo) => {
        return (
          <Tooltip
            key={network.provider}
            type="light"
            title={
              <Stack direction="column">
                <SocialStat
                  title={translate(`components.creator-card.followers`)}
                  value={network.followersCount}
                  titleColor={designSystemToken('semantic.fg.secondary')}
                  valueColor={designSystemToken('semantic.fg.primary')}
                  formatingFunc={getFollowersCountString}
                />
                <SocialStat
                  title={translate(`components.creator-card.eng-rate`)}
                  value={network.engagementRate}
                  titleColor={designSystemToken('semantic.fg.secondary')}
                  valueColor={designSystemToken('semantic.fg.primary')}
                  formatingFunc={getEngagementRateString}
                />
              </Stack>
            }
            placement="top-start"
            arrow
          >
            <div key={network.provider}>
              <Link
                to={network.url}
                target="_blank"
                rel="noopener noreferrer"
                keepBrandIdIfPresent={false}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click event
                  onClickSocialNetwork?.(network.provider);
                }}
              >
                <div className={styles.socialIconContainer}>
                  <SocialNetworkIcon
                    name={network.provider}
                    width={SOCIAL_NETWORK_ICON_SIZE}
                    height={SOCIAL_NETWORK_ICON_SIZE}
                    color={
                      !selectedPlatformProvider ||
                      network.provider.includes(selectedPlatformProvider)
                        ? designSystemToken('semantic.fg.neutral')
                        : designSystemToken('semantic.fg.secondary')
                    }
                  />
                </div>
              </Link>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default CreatorSocialsIcons;
