import {useMutation} from '@tanstack/react-query';
import queryClient from '@/utils/reactQueryClient';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';

/**
 * @name useCreateCreatorGroupLabel
 * @description React hook that creates creator group label
 * @returns {Object} An object containing the mutation state and mutation function
 */
function useCreateCreatorGroupLabel(creatorGroupId?: string): any {
  return useMutation({
    mutationKey: ['creatorGroupsLabels', 'create'],
    mutationFn: creatorGroupUpdaters.createLabel,
    onSuccess: () => {
      queryClient.invalidateQueries(['creatorGroupsLabels', creatorGroupId]);
    },
  });
}

export default useCreateCreatorGroupLabel;
