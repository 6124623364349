import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

type VetCreatorButtonProps = {
  onClick: (event: React.MouseEvent) => void;
  className: string;
};

const TRANSLATION_PREFIX = 'views.creators.components.vet-this-creator-button';

function VetCreatorButton(props: VetCreatorButtonProps) {
  const {onClick, className} = props;
  return (
    <Button
      mode="elevated"
      appearance="neutral"
      size="small"
      onClick={onClick}
      icon={
        <Icon size="small" appearance="neutral" name="Navigation-Discover" />
      }
      className={className}
    >
      {translate(`${TRANSLATION_PREFIX}.button`)}
    </Button>
  );
}

export default VetCreatorButton;
