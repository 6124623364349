import React from 'react';
import {Button, Body} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import useDeleteLabel from '@/hooks/mutations/creators/use-delete-label';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './DeleteList.module.scss';

export type DeleteListProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TRANSLATION_PREFIX = 'components.delete-list';

function DeleteList({label}: {label?: CreatorGroupLabel}) {
  const deleteLabel = useDeleteLabel();
  const onClose = () => {
    listCreatorsActions.setActiveListAction(null);
  };

  const deleteList = async () => {
    if (label?.id) {
      await deleteLabel.mutateAsync(label.id);
      listCreatorsActions.deselectList(label.id);
    }
    onClose();
  };

  return (
    <div className={styles.content}>
      <Body size="md" className={styles.description}>
        {translate(`${TRANSLATION_PREFIX}.description`)}
      </Body>

      <div className={styles.buttons}>
        <Button
          appearance="neutral"
          size="large"
          mode="tinted"
          onClick={onClose}
          className={styles.cancelButton}
        >
          {translate(`${TRANSLATION_PREFIX}.cancel`)}
        </Button>

        <Button
          appearance="neutral"
          size="large"
          mode="filled"
          className={styles.deleteButton}
          onClick={deleteList}
        >
          {translate(`${TRANSLATION_PREFIX}.delete`)}
        </Button>
      </div>
    </div>
  );
}

export default DeleteList;
