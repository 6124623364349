import {useMutation} from '@tanstack/react-query';
import vettingUpdaters from '@/api/updaters/vetting';

/**
 * @name useCreateVetting
 * @description React hook that creates a vetting
 * @returns {Object} An object containing the mutation state and mutation function
 */
function useCreateVetting() {
  return useMutation({
    mutationFn: vettingUpdaters.createVetting,
  });
}

export default useCreateVetting;
