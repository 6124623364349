import i18n from 'i18next';

/**
 * translation helper function
 * @param value
 * @param options
 */
const translate = (value: string, options?: any) => {
  if (i18n.exists(value, options)) {
    return `${i18n.t(value, options)}`;
  }
  return `${value}`;
};

export default translate;
