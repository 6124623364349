import {
  PreferredCreatorAgeDetail,
  PreferredCreatorGender,
} from '@/types/campaign';
import {BaseFilterOption, Range} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FilterSelection,
  FiltersGroupId,
  PlatformFilterIds,
} from '@/types/models/search-creators/filterId';

export type RangeFilterOptionsReturn = {
  range: Range;
  filterSelectionType: FilterSelection;
  filterGroupId: FiltersGroupId;
  filterId: FilterIds;
  platformFilterId?: PlatformFilterIds;
};

export type SingleSelectionFilterOptionsReturn = {
  option: BaseFilterOption;
  filterSelectionType: FilterSelection;
  filterGroupId: FiltersGroupId;
  filterId: FilterIds;
  platformFilterId?: PlatformFilterIds;
};

export type CampaignFiltersStackResult =
  | RangeFilterOptionsReturn
  | SingleSelectionFilterOptionsReturn
  | null;

export function campaignCreatorPlatformMetricsToSearchOptions(
  unlocksAt: number | undefined,
  locksAbove: number | undefined,
  platformFilterId: PlatformFilterIds
): CampaignFiltersStackResult {
  if (!unlocksAt && !locksAbove) return null;
  return {
    range: {
      min: unlocksAt,
      max: locksAbove,
    },
    filterSelectionType: 'rangeSelection',
    filterGroupId: 'platforms',
    filterId: 'platformMetrics',
    platformFilterId,
  };
}

export default function campaignPreferredAgeToSearchOptions(
  campaignPreferredAge: PreferredCreatorAgeDetail
): CampaignFiltersStackResult {
  if (!campaignPreferredAge.minAge && !campaignPreferredAge.minAge) return null;
  return {
    range: {min: campaignPreferredAge.minAge, max: campaignPreferredAge.maxAge},
    filterSelectionType: 'rangeSelection',
    filterGroupId: 'creatorDetails',
    filterId: 'age',
  };
}

function getPlatformId(platformId: string): string {
  if (platformId.includes('instagram')) {
    return 'instagram';
  }
  if (platformId.includes('tiktok')) {
    return 'tiktok';
  }
  return platformId;
}

export function campaignPreferredPlatformToSearchOptions(
  platformId: string
): SingleSelectionFilterOptionsReturn | null {
  if (!platformId) return null;
  const selectedPlatformId = getPlatformId(platformId);
  const platformObject = {
    id: selectedPlatformId,
    localeLabelKey: '',
    value: selectedPlatformId,
  };
  return {
    option: platformObject,
    filterSelectionType: 'singleSelection',
    filterGroupId: 'platforms',
    filterId: 'platform',
  };
}

/* TODO: Campaign can have multiple genders set and returned - this is not implemented here */
export function campaignPreferredGenderToSearchOptions(
  campaignPreferredGender: PreferredCreatorGender[]
): SingleSelectionFilterOptionsReturn | null {
  const gender = campaignPreferredGender[0];
  if (!gender?.name) return null;
  const genderObject = {
    id: gender?.name?.toLowerCase(),
    localeLabelKey: '',
    value: gender?.name?.toLowerCase(),
  };

  return {
    option: genderObject,
    filterSelectionType: 'singleSelection',
    filterGroupId: 'creatorDetails',
    filterId: 'gender',
  };
}

export function combineResults(
  ...results: CampaignFiltersStackResult[]
): CampaignFiltersStackResult[] {
  const combinedArray: (
    | RangeFilterOptionsReturn
    | SingleSelectionFilterOptionsReturn
  )[] = [];

  results.forEach((result: CampaignFiltersStackResult) => {
    if (result) {
      if (Array.isArray(result)) {
        combinedArray.push(...result);
      } else {
        combinedArray.push(result);
      }
    }
  });

  return combinedArray;
}
