import {CreatorSearchResult} from '@/types/models/search-creators/searchCreators';
import {CreatorProps} from '@/views/creators/components/creator-action/action/ActionProps';

function mapCreatorSearchResultsForAction(
  creators: CreatorSearchResult[]
): CreatorProps[] {
  return creators.map((creator) => ({
    id: creator.id,
    name: creator.fullName,
    avatar: creator.picture,
    age: creator.age,
    location: creator.location,
  }));
}

export default mapCreatorSearchResultsForAction;
