import axios from 'axios';
import {pickBy, identity} from 'lodash';
import {getEnv} from '@/config/environment';

const LOCATIONS_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

type Geometry = {
  coordinates: number[];
};

type Context = {
  id: string;
  text_en: string;
};

type Feature = {
  id: string;
  place_type: string[];
  text_en: string;
  place_name_en: string;
  center?: number[];
  geometry: Geometry;
  context?: Context[];
};

type ResponsePayload = {
  type: string;
  query: string[];
  features: Feature[];
  attribution: string;
};

function mapFeatureToLocationValue(feature: Feature) {
  const {context} = feature;
  const placeType = feature.place_type;
  const [longitude, latitude] = feature.center || feature.geometry.coordinates;
  let place;
  let region;
  let country;

  if (context) {
    context.forEach((contextItem) => {
      if (contextItem.id.includes('region')) {
        region = contextItem.text_en;
      } else if (contextItem.id.includes('country')) {
        country = contextItem.text_en;
      }
    });
  }
  if (placeType.includes('place')) {
    place = feature.text_en;
  }
  if (placeType.includes('country')) {
    country = feature.text_en;
  }
  if (placeType.includes('region')) {
    region = feature.text_en;
  }
  return pickBy(
    {
      longitude,
      latitude,
      place,
      region,
      country,
    },
    identity
  );
}

const locationFetchers = {
  locations: async (query: string) => {
    const response = await axios.get<ResponsePayload>(
      `${LOCATIONS_API}/${query}.json?types=place,region,country&limit=10&language=en&access_token=${
        getEnv().VITE_MAPBOX_API_KEY
      }`
    );

    return response.data.features.map((feature: any) => ({
      id: feature.id,
      value: mapFeatureToLocationValue(feature),
      localeLabelKey: feature.text_en,
    }));
  },
};

export default locationFetchers;
