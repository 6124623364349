import React from 'react';
import {Button, Label} from '@lightricks/react-design-system';
import styles from './CreatorCardCta.module.scss';

type CreatorCardCtaProps = {
  buttonText: string;
  onCtaButtonClick: (event: React.MouseEvent) => void;
};

function CreatorCardCta({buttonText, onCtaButtonClick}: CreatorCardCtaProps) {
  return (
    <Button
      className={styles.ctaButton}
      appearance="neutral"
      mode="tinted"
      size="medium"
      onClick={onCtaButtonClick}
    >
      <Label size="md">{buttonText}</Label>
    </Button>
  );
}

export default CreatorCardCta;
