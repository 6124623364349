import {
  AudienceAgeSelection,
  BaseFilterOption,
  DynamicPlatformFilters,
  Filter,
  MultiSelection,
  MultiSelectionAsync,
  MultiUserInput,
  RangeSelection,
  SingleSelection,
  SingleUserInput,
  WeightedFilterOption,
  WeightedSingleSelection,
} from '@/types/models/search-creators/filter';
import {PlatformFilterIds} from '@/types/models/search-creators/filterId';
import locationFetchers from '@/api/fetchers/location';
import {
  DEFAULT_AGE_STEPS,
  DEFAULT_AVERAGE_VIEWS_STEPS,
  DEFAULT_FOLLOWERS_STEPS,
  DEFAULT_WEIGHTS_OPTIONS,
} from '@/stores/search-creators/constants';
import {
  defaultAgeFilterOptions,
  defaultGenderFilterOptions,
  defaultPlatformFilterOptions,
  engagementRateFilterOptions,
} from '@/stores/search-creators/defaultFiltersOptions';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer.filters';

export const genderFilter: SingleSelection<BaseFilterOption> = {
  type: 'singleSelection',
  presentationType: 'singleSelectionOptionsSegmentedControl',
  localeLabelKey: `${TRANSLATION_PREFIX}.gender`,
  options: defaultGenderFilterOptions,
  selected: defaultGenderFilterOptions[0],
};

export const ageFilter: RangeSelection = {
  type: 'rangeSelection',
  presentationType: 'rangeSelectionSliderAndInput',
  localeLabelKey: `${TRANSLATION_PREFIX}.age`,
  steps: DEFAULT_AGE_STEPS,
};

export const locationFilter: MultiSelectionAsync<BaseFilterOption> = {
  type: 'multiSelectionAsync',
  presentationType: 'multiSelectionOptionsAutocompleteAsync',
  localeLabelKey: `${TRANSLATION_PREFIX}.location`,
  getOptions: locationFetchers.locations,
  selected: [],
  placeholderLocaleLabelKey: `${TRANSLATION_PREFIX}.location-placeholder`,
};

export const searchBioFilter: SingleUserInput = {
  type: 'singleUserInput',
  presentationType: 'singleUserInput',
  localeLabelKey: `${TRANSLATION_PREFIX}.bio`,
  tooltipLocaleLabelKey: `${TRANSLATION_PREFIX}.bio`,
  input: '',
  placeholderLocaleLabelKey: `${TRANSLATION_PREFIX}.bio-placeholder`,
};

export const categoryFilter: MultiSelection<BaseFilterOption> = {
  type: 'multiSelection',
  presentationType: 'multiSelectionOptionsList',
  localeLabelKey: `${TRANSLATION_PREFIX}.category`,
  options: [],
  selected: [],
  asyncInitializationKey: 'categories',
};

export const platformFilter: SingleSelection<BaseFilterOption> = {
  type: 'singleSelection',
  presentationType: 'singleSelectionOptionsRadioButtonsListWithIcon',
  localeLabelKey: `${TRANSLATION_PREFIX}.platform`,
  tooltipLocaleLabelKey: `${TRANSLATION_PREFIX}.platform-tooltip`,
  options: defaultPlatformFilterOptions,
  selected: defaultPlatformFilterOptions[0],
};

export const weightedGenderFilter: WeightedSingleSelection<WeightedFilterOption> =
  {
    ...genderFilter,
    options: defaultGenderFilterOptions.filter(
      (gender) => gender.id !== 'other'
    ),
    localeLabelKey: `${TRANSLATION_PREFIX}.audience-gender`,
    type: 'weightedSingleSelection',
    presentationType: 'weightedSingleSelectionOptionsSegmentedControl',
    weights: DEFAULT_WEIGHTS_OPTIONS,
    weightPlaceholderLocaleLabelKey: `${TRANSLATION_PREFIX}.weight-placeholder`,
    isIrrelevant: true,
  };

export const audienceAgeFilter: AudienceAgeSelection = {
  localeLabelKey: `${TRANSLATION_PREFIX}.audience-age`,
  type: 'audienceAge',
  presentationType: 'audienceAge',
  options: defaultAgeFilterOptions,
  selected: [],
  ldaSelected: false,
  ldaLocaleLabelKey: 'lda',
  weights: DEFAULT_WEIGHTS_OPTIONS,
  placeholderLocaleLabelKey: `${TRANSLATION_PREFIX}.age-placeholder`,
  weightPlaceholderLocaleLabelKey: `${TRANSLATION_PREFIX}.weight-placeholder-short`,
  isIrrelevant: true,
};

export const audienceLocationFilter: MultiSelectionAsync<WeightedFilterOption> =
  {
    ...locationFilter,
    localeLabelKey: `${TRANSLATION_PREFIX}.audience-location`,
    isIrrelevant: true,
  };

export const followersFilter: RangeSelection = {
  type: 'rangeSelection',
  presentationType: 'rangeSelectionSliderAndInput',
  localeLabelKey: `${TRANSLATION_PREFIX}.followers`,
  steps: DEFAULT_FOLLOWERS_STEPS,
};

export const subscribersFilter: RangeSelection = {
  ...followersFilter,
  localeLabelKey: `${TRANSLATION_PREFIX}.subscribers`,
};

export const averageViewsFilter: RangeSelection = {
  type: 'rangeSelection',
  presentationType: 'rangeSelectionSliderAndInput',
  localeLabelKey: `${TRANSLATION_PREFIX}.average-views`,
  steps: DEFAULT_AVERAGE_VIEWS_STEPS,
};

export const engagementRateFilter: SingleSelection<BaseFilterOption> = {
  type: 'singleSelection',
  presentationType: 'singleSelectionOptionsDropDown',
  localeLabelKey: `${TRANSLATION_PREFIX}.engagement-rate`,
  options: engagementRateFilterOptions,
  placeholderLocaleLabelKey: `${TRANSLATION_PREFIX}.weight-placeholder`,
};

const basePlatformFilters: Partial<Record<PlatformFilterIds, Filter>> = {
  followers: followersFilter,
  engagementRate: engagementRateFilter,
};

export const platformMetricsFilter: DynamicPlatformFilters = {
  type: 'dynamicPlatformFilters',
  presentationType: 'platformFiltersGroup',
  localeLabelKey: `${TRANSLATION_PREFIX}.platform-metrics`,
  platformId: 'all',
  platformsFilters: {
    instagram: {
      ...basePlatformFilters,
    },
    tiktok: {
      ...basePlatformFilters,
      averageViews: averageViewsFilter,
    },
    twitter: {...basePlatformFilters},
    youtube: {
      followers: subscribersFilter,
      engagementRate: engagementRateFilter,
      averageViews: averageViewsFilter,
    },
    facebook: {...basePlatformFilters},
    pinterest: {...basePlatformFilters},
    all: {...basePlatformFilters},
  },
};

export const keywordsFilter: MultiUserInput = {
  type: 'multiUserInput',
  presentationType: 'multiUserInputWithChips',
  localeLabelKey: `${TRANSLATION_PREFIX}.keywords`,
  tooltipLocaleLabelKey: `${TRANSLATION_PREFIX}.keywords-tooltip`,
  inputs: [],
  placeholderLocaleLabelKey: `${TRANSLATION_PREFIX}.keywords-placeholder`,
};
