import {create} from 'zustand';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import {CreatorActionKey} from '@/views/creators/components/creator-action/CreatorAction';

export type ListActionKey =
  | 'add-list'
  | 'edit-list'
  | 'delete-list'
  | 'share-list';

export type ListCreatorsStateDefinition = {
  selectedLists: Set<string>;
  selectedCreators: Map<string, CreatorGroupMembership>;
  activeCreatorAction: CreatorActionKey | null;
  activeListAction: ListActionKey | null;
  activeListId: string | null;
  tableFilterValue: string | null;
  membershipsPage: number;
};

const listCreatorsStore = create<ListCreatorsStateDefinition>(() => ({
  selectedLists: new Set(),
  selectedCreators: new Map(),
  activeCreatorAction: null,
  activeListAction: null,
  activeListId: null,
  tableFilterValue: null,
  membershipsPage: 1,
}));

const getNewSelectedCreators = ({
  selectedLists,
  selectedCreators,
}: {
  selectedLists: ListCreatorsStateDefinition['selectedLists'];
  selectedCreators: ListCreatorsStateDefinition['selectedCreators'];
}) => {
  return new Map<string, CreatorGroupMembership>(
    [...selectedCreators].filter(([, membership]) =>
      membership.labels.some(({id}) => selectedLists.has(id))
    )
  );
};

const selectList = (listId: string) => {
  const {selectedLists, selectedCreators} = listCreatorsStore.getState();

  const newSelectedLists = new Set(selectedLists);
  newSelectedLists.add(listId);

  const newSelectedCreators = getNewSelectedCreators({
    selectedLists: newSelectedLists,
    selectedCreators,
  });

  listCreatorsStore.setState({
    membershipsPage: 1,
    selectedLists: newSelectedLists,
    selectedCreators: newSelectedCreators,
  });
};

const deselectList = (listId: string) => {
  const {selectedLists, selectedCreators} = listCreatorsStore.getState();

  const newSelectedLists = new Set(selectedLists);
  newSelectedLists.delete(listId);

  const newSelectedCreators = getNewSelectedCreators({
    selectedLists: newSelectedLists,
    selectedCreators,
  });

  listCreatorsStore.setState({
    membershipsPage: 1,
    selectedLists: newSelectedLists,
    selectedCreators:
      newSelectedLists.size === 0 ? selectedCreators : newSelectedCreators,
  });
};

const toggleList = (listId: string) => {
  if (listCreatorsStore.getState().selectedLists.has(listId)) {
    deselectList(listId);
  } else {
    selectList(listId);
  }
};

const selectCreator = (id: string, creator: CreatorGroupMembership) => {
  const newSelectedCreators = new Map<string, CreatorGroupMembership>(
    listCreatorsStore.getState().selectedCreators
  );
  newSelectedCreators.set(id, creator);
  listCreatorsStore.setState({selectedCreators: newSelectedCreators});
};

const deselectCreator = (creatorId: string) => {
  const newSelectedCreators = new Map<string, CreatorGroupMembership>(
    listCreatorsStore.getState().selectedCreators
  );
  newSelectedCreators.delete(creatorId);
  listCreatorsStore.setState({selectedCreators: newSelectedCreators});
};

const toggleCreator = (id: string, creator: CreatorGroupMembership) => {
  if (listCreatorsStore.getState().selectedCreators.has(id)) {
    deselectCreator(id);
  } else {
    selectCreator(id, creator);
  }
};

const selectCreators = (creators: CreatorGroupMembership[]) => {
  const newSelectedCreators = new Map<string, CreatorGroupMembership>(
    listCreatorsStore.getState().selectedCreators
  );
  creators.forEach((creator) => newSelectedCreators.set(creator.id, creator));
  listCreatorsStore.setState({selectedCreators: newSelectedCreators});
};

const deselectAllCreators = () => {
  listCreatorsStore.setState({selectedCreators: new Map()});
};

const showAllCreators = () => {
  deselectAllLists();
  setTableFilterValue(null);
};

const selectMultipleLists = (listIds: string[]) => {
  const newSelectedLists = new Set<string>(
    listCreatorsStore.getState().selectedLists
  );
  listIds.forEach((listId) => newSelectedLists.add(listId));
  listCreatorsStore.setState({selectedLists: newSelectedLists});
};

const deselectAllLists = () => {
  listCreatorsStore.setState({selectedLists: new Set()});
};

const setTableFilterValue = (value: string | null) => {
  listCreatorsStore.setState({tableFilterValue: value, membershipsPage: 1});
};

const clearTableFilterValue = () => {
  setTableFilterValue(null);
};

const setActiveCreatorAction = (creatorAction: CreatorActionKey | null) => {
  listCreatorsStore.setState({activeCreatorAction: creatorAction});
};

const setActiveListAction = (listAction: ListActionKey | null, id?: string) => {
  if (id) setActiveListId(id);
  listCreatorsStore.setState({activeListAction: listAction});
};

const setActiveListId = (listId: string | null) => {
  listCreatorsStore.setState({activeListId: listId});
};

const setMembershipsPage = (page: number) => {
  listCreatorsStore.setState({membershipsPage: page});
};

export const listCreatorsActions = {
  selectList,
  deselectList,
  toggleList,
  selectMultipleLists,
  deselectAllLists,
  selectCreator,
  deselectCreator,
  toggleCreator,
  selectCreators,
  deselectAllCreators,
  setTableFilterValue,
  clearTableFilterValue,
  setActiveCreatorAction,
  setActiveListAction,
  setActiveListId,
  showAllCreators,
  setMembershipsPage,
};

export default listCreatorsStore;
