import QueryContext from '@/api/QueryContext';
import api from '@/api';

const creatorGroupsFetchers = {
  labels: (context: QueryContext) =>
    api.get(`/creator_groups/${context.meta?.creatorGroupId}/labels`),
  memberships: (context: QueryContext) =>
    api.get(`/creator_groups/${context.meta?.creatorGroupId}/memberships`, {
      params: {
        page: context.meta?.page || 1,
        per_page: context.meta?.perPage || 25,
        include:
          context.meta?.include ||
          'profile.identities,profile.current_brand_collaboration_conversation,labels',
        q: context.meta?.q || '',
        ...(context.meta?.labelIds
          ? {label_ids: (context.meta.labelIds as string[]).join(',')}
          : {}),
        ...(context.meta?.unlabeled ? {unlabeled: true} : {}),
      },
    }),
};

export default creatorGroupsFetchers;
