import {MRT_Row} from 'material-react-table';
import React from 'react';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import UsernameCell from '@/views/creators/components/creators-table/UsernameCell';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

function CreatorNameCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  const {id} = row.original;
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const imageUrl = row.original.profile?.imageUrl ?? '';
  const displayName =
    row.original.profile?.displayName ??
    row.original.email ??
    'Deactivated creator';

  return (
    <UsernameCell
      showProfilePopup
      name={displayName}
      picture={imageUrl}
      isSelected={selectedCreators.has(id)}
      onCheckboxChange={() => {
        listCreatorsActions.toggleCreator(id, row.original);
      }}
      profile={row.original.profile}
      email={row.original?.email}
    />
  );
}

export default CreatorNameCell;
