import React from 'react';
import {Divider, Menu, MenuItem} from '@mui/material';
import {Button, designSystemToken, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import {CreatorCardProps} from '@/components/creator-card/CreatorCard';
import styles from './CreatorOverflowMenuButton.module.scss';

type CreatorOverflowMenuButtonProps = {
  isEnabled?: boolean;
  creator: CreatorCardProps;
  classes?: {container?: string; button?: string};
  menuItems: CreatorMenuItemProps[];
};

export type CreatorMenuItemProps = {
  iconName: IconName;
  title: string;
  onClick: (creator: CreatorCardProps) => void;
};

function CreatorOverflowMenuButton({
  classes = {},
  isEnabled,
  creator,
  menuItems,
}: CreatorOverflowMenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <div className={classes.container}>
      {isEnabled && (
        <Button
          className={`${styles.overflowMenuButton} ${classes.button} ${
            menuOpen ? styles.menuOpen : ''
          }`}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
          icon={<Icon name="Actions-More" appearance="inverse" size="medium" />}
          appearance="overlay"
          size="medium"
          mode="filled"
        />
      )}
      <Menu
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '24px',
            minWidth: '343px',
            paddingInline: '8px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={menuOpen}
        onClose={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          event.preventDefault();
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
      >
        {menuItems.map((item, index) => (
          <div key={item.title}>
            <CreatorMenuItem
              creator={creator}
              menuItem={{
                ...item,
                onClick: (c: CreatorCardProps) => {
                  item.onClick(c);
                  setAnchorEl(null);
                },
              }}
            />
            {index === menuItems.length - 1 ? null : <Divider />}
          </div>
        ))}
      </Menu>
    </div>
  );
}

function CreatorMenuItem({
  menuItem,
  creator,
}: {
  menuItem: CreatorMenuItemProps;
  creator: CreatorCardProps;
}) {
  return (
    <MenuItem
      sx={{
        'display': 'flex',
        'gap': '12px',
        'padding': '16px 12px 16px 12px',
        '&:hover': {
          background: designSystemToken('semantic.bg.tertiary'),
          borderRadius: '16px',
        },
      }}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        menuItem.onClick(creator);
      }}
    >
      <Icon appearance="neutral" size="medium" name={menuItem.iconName} />
      {menuItem.title}
    </MenuItem>
  );
}

export default CreatorOverflowMenuButton;
