import api from '../index';

type Vetting = {
  userId: string;
  merchantId: string;
  feedback?: string;
  difficultyLevel?: number;
};

const vettingUpdaters = {
  createVetting: async (vetting: Vetting) => {
    return api.post(`/vettings`, {
      user_id: vetting.userId,
      merchant_id: vetting.merchantId,
      feedback: vetting.feedback,
      difficulty_level: vetting.difficultyLevel,
    });
  },
};

export default vettingUpdaters;
