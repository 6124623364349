import {partition} from 'lodash';
import React from 'react';
import {Box, Popover, styled} from '@mui/material';
import {Checkbox, Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import {
  ListDrawerAccordion,
  NewListButton,
} from '@/components/list-drawer/components';
import useUpdateMembershipLabels from '@/hooks/mutations/use-update-membership-labels';
import useMembershipsAndLabels from '../useMembershipsAndLabels';
import styles from './ChangeListPopover.module.scss';

const TRANSLATION_PREFIX = 'components.list-creators-table';

type ChangeListPopoverProps = {
  membership: CreatorGroupMembership;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
};

function ChangeListPopover({
  membership,
  open,
  onClose,
  anchorEl,
}: ChangeListPopoverProps) {
  const updateMembershipLablesMutation = useUpdateMembershipLabels();
  const {labels, isLoading} = useMembershipsAndLabels();
  const isSharedList = ({organizationLevel}: CreatorGroupLabel) =>
    organizationLevel === true;

  const [sharedLists, brandLists] = partition(labels, isSharedList);

  const Container = styled(Box)`
    .MuiFormHelperText-root {
      display: none;
    }
  `;

  const checked = (label: CreatorGroupLabel) =>
    membership.labels.map(({id}) => id).includes(label.id);

  const onChange = (label: CreatorGroupLabel) => async () => {
    const updatedLabels = checked(label)
      ? membership.labels.filter((memberLabel) => memberLabel.id !== label.id)
      : [...membership.labels, label];

    await updateMembershipLablesMutation.mutateAsync({
      membership,
      labels: updatedLabels,
    });
  };

  return (
    <Popover
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      open={open}
      classes={{paper: styles.paper}}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
      }}
    >
      <Container>
        <Headline size="sm" sx={{p: 3}}>
          {translate(`${TRANSLATION_PREFIX}.change-list-popover.title`)}
        </Headline>
        <div className={styles.listsContainer}>
          <ListDrawerAccordion label="Shared Lists" count={sharedLists.length}>
            {sharedLists.map((label) => (
              <ChangeListOption
                label={label}
                checked={checked}
                onChange={onChange}
                key={label.id}
              />
            ))}
          </ListDrawerAccordion>
          <ListDrawerAccordion label="Brand Lists" count={brandLists.length}>
            {brandLists.map((label) => (
              <ChangeListOption
                label={label}
                checked={checked}
                onChange={onChange}
                key={label.id}
              />
            ))}
          </ListDrawerAccordion>
        </div>
        <hr />
        <div className={styles.newListButtonContainer}>
          <NewListButton isListItem={false} />
        </div>
      </Container>
    </Popover>
  );
}

function ChangeListOption({
  label,
  checked,
  onChange,
}: {
  label: CreatorGroupLabel;
  checked: (label: CreatorGroupLabel) => boolean;
  onChange: (label: CreatorGroupLabel) => () => void;
}) {
  const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
  const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

  return (
    <Box sx={{px: 2, py: 1.5}} key={label.id}>
      <Checkbox
        customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
        customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
        checked={checked(label)}
        onChange={onChange(label)}
        label={
          <Box>
            {label.name} ({label.creatorCount})
          </Box>
        }
      />
    </Box>
  );
}

export default ChangeListPopover;
