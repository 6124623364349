import {WritableDraft} from 'immer/src/types/types-external';
import objectHash from 'object-hash';
import ColumnId from '@/types/models/search-creators/columnId';
import {
  AudienceAgeSelection,
  BaseFilterOption,
  DynamicPlatformFilters,
  Filter,
  FiltersState,
  Identifiable,
  MultiSelection,
  MultiUserInput,
  RangeSelection,
  SingleSelection,
  SingleUserInput,
  Weighted,
  WeightedMultiSelection,
  WeightedSingleSelection,
} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FilterOptionSelection,
  FilterSelection,
  FiltersGroupId,
  PlatformFilterIds,
  PlatformId,
} from '@/types/models/search-creators/filterId';
import {
  LdaUpdate,
  WeightUpdate,
} from '@/types/models/search-creators/filterUpdate';
import {
  FilterOptionResult,
  Metadata,
} from '@/types/models/search-creators/searchCreators';
import {Content} from '@/types/models/search-creators/searchCreatorsStore';
import {initialPaging} from '@/stores/search-creators/searchCreatorsStore';

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

export function getColumnIdFromString(value: string): ColumnId | undefined {
  // Directly check if the value is one of the `ColumnId` values.
  if (Object.values(ColumnId).includes(value as ColumnId)) {
    return value as ColumnId;
  }
  return undefined;
}

export function setUpdatedFilter(
  filter: Filter | undefined,
  filterSelectionType: FilterOptionSelection,
  option: BaseFilterOption
): Filter | undefined {
  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return undefined;
  }
  let updatedFilter;
  switch (filterSelectionType) {
    case 'weightedSingleSelection':
    case 'singleSelection': {
      updatedFilter = toggleSingleSelectionFilter(
        filter as SingleSelection<Identifiable>,
        option
      );
      break;
    }
    case 'multiSelection':
    case 'multiSelectionAsync':
    case 'audienceAge':
    case 'weightedMultiSelection': {
      updatedFilter = toggleMultiSelectionFilter(
        filter as MultiSelection<Identifiable>,
        option
      );
      break;
    }
    default:
      assertNever(filterSelectionType);
  }

  return updatedFilter;
}

export function findExpandedFilter(
  filtersState: FiltersState,
  filterSelectionType: FilterSelection,
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  platformFilterId?: PlatformFilterIds
): Filter | undefined {
  // Check that correct Filters Group is opened
  if (filtersState.openedFiltersGroup !== filtersGroupId) {
    return undefined;
  }
  const filterGroup = filtersState.filtersGroups[filtersGroupId];
  // Check that correct Filter is expanded
  if (filterGroup.isCollapsable && filterGroup?.expandedFilter !== filterId) {
    return undefined;
  }
  // Retrieve nested or direct filter
  const filter = getDirectOrNestedFilter(
    filterGroup.filters,
    filterId,
    platformFilterId
  );
  // Check that filter has correct selection type
  if (filter?.type !== filterSelectionType) {
    return undefined;
  }
  return filter;
}

export function getDirectOrNestedFilter(
  filters: Partial<Record<FilterIds, Filter>>,
  filterId: FilterIds,
  platformFilterId?: PlatformFilterIds
): Filter | undefined {
  if (platformFilterId) {
    const dynamicPlatformFilters = filters[filterId] as
      | DynamicPlatformFilters
      | undefined;

    const platformFilters = dynamicPlatformFilters
      ? dynamicPlatformFilters.platformsFilters[
          dynamicPlatformFilters.platformId
        ]
      : undefined;
    return platformFilters && platformFilters[platformFilterId];
  }
  return filters[filterId];
}

export function toggleSingleSelectionFilter(
  filter: SingleSelection<Identifiable>,
  option: BaseFilterOption
): SingleSelection<Identifiable> {
  if (filter.selected?.id === option.id) {
    return {...filter, selected: undefined};
  }
  const selectedOption = filter.options.find(
    (_option) => _option.id === option.id
  );
  if (selectedOption) {
    return {...filter, selected: selectedOption};
  }
  return filter;
}

export function toggleMultiSelectionFilter(
  filter: MultiSelection<Identifiable>,
  option: BaseFilterOption
): MultiSelection<Identifiable> {
  const unselectFilterIndex = filter.selected.findIndex(
    (selectedOption) => selectedOption.id === option.id
  );
  if (unselectFilterIndex !== -1) {
    return {
      ...filter,
      selected: filter.selected.filter(
        (selectedOption) => selectedOption.id !== option.id
      ),
    };
  }

  if (option) {
    return {...filter, selected: [...filter.selected, option]};
  }
  return filter;
}

export function updateLda(
  filterSelectionType: FilterSelection,
  filter: Filter,
  update: LdaUpdate
): Filter {
  if (filterSelectionType === 'audienceAge') {
    return {
      ...(filter as AudienceAgeSelection),
      ldaSelected: update.isLdaSelected,
      selected: [],
    } as AudienceAgeSelection;
  }
  return filter;
}

export function updateWeight(
  filterSelectionType: FilterSelection,
  filter: Filter,
  update: WeightUpdate
): Filter {
  switch (filterSelectionType) {
    case 'weightedMultiSelection':
    case 'audienceAge':
      return updateMultiSelectionWeight(
        filter as WeightedMultiSelection<Identifiable & Weighted>,
        update.optionId,
        update.weight
      );
    case 'weightedSingleSelection':
      return updateSingleSelectionWeight(
        filter as WeightedSingleSelection<Identifiable & Weighted>,
        update.optionId,
        update.weight
      );
    default:
      return filter;
  }
}

export function updateMultiSelectionWeight(
  filter: WeightedMultiSelection<Identifiable & Weighted>,
  optionId: string,
  weight: string
): WeightedMultiSelection<Identifiable & Weighted> {
  if (filter.weights[weight]) {
    return {
      ...filter,
      selected: filter.selected.map((option) => {
        if (option.id === optionId) {
          return {
            ...option,
            weight:
              option.weight !== filter.weights[weight]
                ? filter.weights[weight]
                : undefined,
          };
        }
        return option;
      }),
    };
  }
  return filter;
}

export function updateSingleSelectionWeight(
  filter: WeightedSingleSelection<Identifiable & Weighted>,
  optionId: string,
  weight: string
): WeightedSingleSelection<Identifiable & Weighted> {
  if (filter.weights[weight]) {
    if (filter.selected?.id === optionId) {
      return {
        ...filter,
        selected: {
          ...filter.selected,
          weight:
            filter.selected.weight !== filter.weights[weight]
              ? filter.weights[weight]
              : undefined,
        },
      };
    }
  }
  return filter;
}

function mapMetadataToOptions(
  metadata: FilterOptionResult[]
): BaseFilterOption[] {
  return metadata.map((option) => ({
    id: option.code,
    localeLabelKey: option.name,
    value: option.code,
  }));
}

/**
 * Update filter options with metadata. There are filters that are
 * async initializable. This function fills the options of the filter with
 * the data from the metadata.
 * @param filter - filter to update
 * @param metadata - metadata to update filter options
 */
export function updateAsyncInitializableFilterOptions(
  filter: WritableDraft<Filter>,
  metadata: Metadata
) {
  if (filter.asyncInitializationKey) {
    switch (filter.type) {
      case 'singleSelection':
      case 'weightedSingleSelection':
        (filter as WritableDraft<SingleSelection<Identifiable>>).options =
          mapMetadataToOptions(metadata[filter.asyncInitializationKey]);
        break;
      case 'multiSelection':
      case 'audienceAge':
      case 'weightedMultiSelection':
        (filter as WritableDraft<MultiSelection<Identifiable>>).options =
          mapMetadataToOptions(metadata[filter.asyncInitializationKey]);
        break;
      case 'multiSelectionAsync':
        // Do nothing - options are loaded asynchronously on each input change
        break;
      default:
    }
  }
}

export function resetSelected(
  filter: WritableDraft<Filter>,
  initialFilter: Filter
) {
  switch (filter.type) {
    case 'singleSelection':
    case 'weightedSingleSelection':
      (filter as WritableDraft<SingleSelection<Identifiable>>).selected = (
        initialFilter as SingleSelection<Identifiable>
      ).selected;
      break;
    case 'multiSelection':
    case 'multiSelectionAsync':
    case 'audienceAge': {
      const castedInitialFilter = initialFilter as AudienceAgeSelection;
      (filter as WritableDraft<AudienceAgeSelection>).selected =
        castedInitialFilter.selected;
      (filter as WritableDraft<AudienceAgeSelection>).ldaSelected =
        castedInitialFilter.ldaSelected;
      break;
    }
    case 'weightedMultiSelection':
      (filter as WritableDraft<MultiSelection<Identifiable>>).selected = (
        initialFilter as MultiSelection<Identifiable>
      ).selected;
      break;
    case 'rangeSelection':
      (filter as WritableDraft<RangeSelection>).selected = (
        initialFilter as RangeSelection
      ).selected;
      break;
    case 'multiUserInput':
      (filter as WritableDraft<MultiUserInput>).inputs = (
        initialFilter as MultiUserInput
      ).inputs;
      break;
    case 'singleUserInput':
      (filter as WritableDraft<SingleUserInput>).input = (
        initialFilter as SingleUserInput
      ).input;
      break;
    case 'dynamicPlatformFilters':
      {
        const initialDynamicPlatformFilters =
          initialFilter as DynamicPlatformFilters;
        const dynamicPlatformFilters =
          filter as WritableDraft<DynamicPlatformFilters>;
        Object.keys(initialDynamicPlatformFilters.platformsFilters).forEach(
          (_platformId) => {
            const platformId = _platformId as PlatformId;
            Object.keys(
              initialDynamicPlatformFilters.platformsFilters[platformId]
            ).forEach((_platformFilterId) => {
              const platformFilterId = _platformFilterId as PlatformFilterIds;
              const initialPlatformFilter =
                initialDynamicPlatformFilters.platformsFilters[platformId][
                  platformFilterId
                ];
              const platformFilter =
                dynamicPlatformFilters.platformsFilters[platformId][
                  platformFilterId
                ];
              if (platformFilter && initialPlatformFilter) {
                resetSelected(platformFilter, initialPlatformFilter);
              }
            });
          }
        );
      }
      break;
    default:
  }
}

function isApplied(filter: Filter): boolean {
  switch (filter.type) {
    case 'singleSelection':
    case 'weightedSingleSelection':
      return !!(filter as SingleSelection<BaseFilterOption>).selected?.value;
    case 'multiSelection':
    case 'weightedMultiSelection':
    case 'multiSelectionAsync':
      return !!(filter as MultiSelection<BaseFilterOption>).selected.length;
    case 'audienceAge':
      return (
        !!(filter as AudienceAgeSelection).selected.length ||
        (filter as AudienceAgeSelection).ldaSelected
      );
    case 'rangeSelection':
      return !!(filter as RangeSelection).selected;
    case 'multiUserInput':
      return !!(filter as MultiUserInput).inputs;
    case 'singleUserInput':
      return !!(filter as SingleUserInput).input;
    case 'dynamicPlatformFilters': {
      const dynamicPlatformFilters = filter as DynamicPlatformFilters;
      const selectedPlatformFilter =
        dynamicPlatformFilters.platformsFilters[
          dynamicPlatformFilters.platformId
        ];
      return Object.values<Filter>(selectedPlatformFilter).some(
        (platformFilter) => {
          return isApplied(platformFilter);
        }
      );
    }
    default:
      return false;
  }
}

export function hasSelectedFilters(
  filters: Partial<Record<FilterIds, Filter>>
): boolean {
  return Object.values<Filter>(filters).some((filter) => {
    return isApplied(filter);
  });
}

export function isFiltersGroupChanged(
  filters: Partial<Record<FilterIds, Filter>>,
  snapshotFilters: Partial<Record<FilterIds, Filter>>
): boolean {
  const hashOptions = {
    unorderedArrays: true,
    unorderedObjects: true,
  };
  return (
    objectHash(filters, hashOptions) !==
    objectHash(snapshotFilters, hashOptions)
  );
}

export function resetSessionInformation(draft: WritableDraft<Content>) {
  draft.sessionId = undefined;
  draft.paging = {...initialPaging};
  draft.selectedCreators = new Map();
}

export function resetFilterDrawerState(draft: WritableDraft<FiltersState>) {
  if (draft.openedFiltersGroup) {
    draft.filtersGroups[draft.openedFiltersGroup].expandedFilter = undefined;
  }
  draft.openedFiltersGroup = undefined;
}
