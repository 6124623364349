import {Placement, Rect} from '@popperjs/core';
import {Modifier} from '@popperjs/core/lib/types';
import {Property} from 'csstype';
import React, {useRef} from 'react';
import {Box, Fade, Popper} from '@mui/material';
import useResizeObserver from '@/hooks/use-resize-observer';
import styles from './FloatingPopperBar.module.scss';

export type FloatingPopperBarProps = {
  testID?: string;
  children: React.ReactNode;
  isOpen?: boolean;
  placement: Placement;
  widthCalculation: (width?: number) => Property.Width<number>;
  placementMargin?: number;
  renderContent?: () => React.ReactNode;
};

function getPopperModifiers(
  additionalMargin = 0
): Array<Partial<Modifier<string, object>>> {
  function getYOffset(placement: Placement, popper: Rect): number {
    if (placement === 'bottom') {
      return -popper.height - additionalMargin;
    }
    if (placement === 'top') {
      return popper.height + additionalMargin;
    }
    return 0;
  }

  return [
    {
      name: 'flip',
      enabled: false,
    },
    {
      name: 'preventOverflow',
      enabled: false,
    },
    {
      name: 'offset',
      options: {
        offset: ({
          placement,
          popper,
        }: {
          reference: Rect;
          placement: Placement;
          popper: Rect;
        }) => [/* x offset */ 0, /* y offset */ getYOffset(placement, popper)],
      },
    },
  ];
}

function FloatingPopperBar(props: FloatingPopperBarProps) {
  const {
    testID = 'floating-popper-bar',
    children,
    isOpen = false,
    placement = 'bottom',
    widthCalculation,
    renderContent,
    placementMargin,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const {width} = useResizeObserver(containerRef);

  return (
    <>
      <div className={styles.container} ref={containerRef} data-testid={testID}>
        {children}
      </div>
      <Popper
        id="floating-popper-bar"
        anchorEl={containerRef.current}
        open={isOpen}
        placement={placement}
        popperOptions={{strategy: 'fixed'}}
        transition
        modifiers={getPopperModifiers(placementMargin)}
        style={{width: widthCalculation(width)}}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={styles.floatingPopperBar}>{renderContent?.()}</Box>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default FloatingPopperBar;
