import React from 'react';
import {
  Body,
  Button,
  designSystemToken,
  Icon,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './Pagination.module.scss';

export type PaginationProps = {
  pageIndex: number;
  totalPages?: number;
  isLoadingPage?: boolean;
  setPagination: (pageIndex: number) => void;
};
function Pagination(props: PaginationProps) {
  const {pageIndex, totalPages, isLoadingPage = false, setPagination} = props;

  const onArrowClick = (direction: string) => {
    const newPageIndex =
      direction === 'forward' ? pageIndex + 1 : pageIndex - 1;
    setPagination(newPageIndex);
  };

  const getCurrentPageText = () => {
    if (totalPages === -1 || totalPages === 0) {
      return '';
    }

    return translate('views.creators.pagination', {
      currentPage: pageIndex.toLocaleString(),
      totalPages: totalPages?.toLocaleString(),
    });
  };

  const arrowButton = (direction: string, isDisabled: boolean) => {
    return (
      <Button
        appearance="overlay"
        mode="filled"
        size="small"
        onClick={() => onArrowClick(direction)}
        disabled={isDisabled}
        className={styles.button}
        icon={
          <Icon
            size="medium"
            appearance="neutral"
            name={`Actions-Arrow-${
              direction === 'back' ? 'Back' : 'Forward'
            }-Small`}
          />
        }
      />
    );
  };

  return (
    <div className={styles.paginationContainer}>
      <Body
        size="sm"
        color={designSystemToken('semantic.fg.inverse-secondary')}
        className={styles.pageText}
      >
        {getCurrentPageText()}
      </Body>
      <div className={styles.buttonsContainer}>
        {arrowButton('back', pageIndex === 1)}
        {arrowButton(
          'forward',
          totalPages === pageIndex ||
            totalPages === -1 ||
            totalPages === 0 ||
            isLoadingPage
        )}
      </div>
    </div>
  );
}
export default Pagination;
