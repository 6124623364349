import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import creatorGroupsFetchers from '@/api/fetchers/creatorGroups';
import useDataQuery from '@/hooks/use-data-query';

type UseCreatorGroupsLabelsQueryParams = {
  creatorGroupId?: string;
  refetchOnWindowFocus?: boolean;
};

function useCreatorGroupsLabelsQuery({
  creatorGroupId,
  refetchOnWindowFocus = true,
}: UseCreatorGroupsLabelsQueryParams) {
  const response = useDataQuery({
    queryKey: ['creatorGroupsLabels', creatorGroupId],
    queryFn: creatorGroupsFetchers.labels,
    enabled: !!creatorGroupId,
    meta: {creatorGroupId},
    refetchOnWindowFocus,
  });

  return {
    ...response,
    labels: (response.data || []) as CreatorGroupLabel[],
  };
}

export default useCreatorGroupsLabelsQuery;
