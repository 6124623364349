import {useEffect, useState} from 'react';
import {CampaignProps} from '@/types/campaign';
import campaignPreferredAgeToSearchOptions, {
  campaignCreatorPlatformMetricsToSearchOptions,
  campaignPreferredGenderToSearchOptions,
  campaignPreferredPlatformToSearchOptions,
  combineResults,
  CampaignFiltersStackResult,
} from '@/views/creators/search/utils/campaignValuesToSearchOptions';

const useCreateFiltersStackFromCampaignData = (
  selectedCampaign: CampaignProps | null,
  uniqueSelectActionTrigger: string,
  isLoadingPrefetchedData: boolean
) => {
  const [campaignFiltersStack, setCampaignFiltersStack] = useState<
    CampaignFiltersStackResult[]
  >([]);

  useEffect(() => {
    if (!selectedCampaign || isLoadingPrefetchedData) {
      return;
    }

    const {preferredCreatorAgeDetail, preferredCreatorGenders, socialNetwork} =
      selectedCampaign;

    const followersOption: CampaignFiltersStackResult =
      campaignCreatorPlatformMetricsToSearchOptions(
        selectedCampaign.unlocksAt,
        selectedCampaign.locksAbove as number | undefined,
        'followers'
      );

    if (!preferredCreatorAgeDetail) {
      return;
    }
    const ageOption: CampaignFiltersStackResult =
      campaignPreferredAgeToSearchOptions(preferredCreatorAgeDetail);

    if (!preferredCreatorGenders) {
      return;
    }
    const genderOptions: CampaignFiltersStackResult =
      campaignPreferredGenderToSearchOptions(preferredCreatorGenders);

    const platformOption: CampaignFiltersStackResult =
      campaignPreferredPlatformToSearchOptions(
        socialNetwork ? socialNetwork.id : ''
      );

    const filtersStack = combineResults(
      ageOption,
      followersOption,
      genderOptions,
      platformOption
    );

    setCampaignFiltersStack(filtersStack);
  }, [selectedCampaign, uniqueSelectActionTrigger, isLoadingPrefetchedData]);

  return selectedCampaign ? campaignFiltersStack : [];
};

export default useCreateFiltersStackFromCampaignData;
