import {useMutation} from '@tanstack/react-query';
import creatorsUpdaters from '@/api/updaters/creators';

function useAddToCampaign() {
  return useMutation({
    mutationKey: ['creators', 'addToCampaign'],
    mutationFn: creatorsUpdaters.addToCampaign,
  });
}

export default useAddToCampaign;
