import React from 'react';
import {Paper, Stack} from '@mui/material';
import {SocialNetworkInfo} from '@/types/socials';
import Badge from '@/components/creator-card/Badge';
import CreatorAvatar from '@/components/creator-card/CreatorAvatar';
import CreatorCardCta from '@/components/creator-card/CreatorCardCta';
import CreatorCategories from '@/components/creator-card/CreatorCategories';
import CreatorInfo from '@/components/creator-card/CreatorInfo';
import CreatorOverflowMenuButton, {
  CreatorMenuItemProps,
} from '@/components/creator-card/CreatorOverflowMenuButton';
import CreatorSocialInfo from '@/components/creator-card/CreatorSocialInfo';
import CreatorSocialsIcons from '@/components/creator-card/CreatorSocialsIcons';
import ImagesStack from '@/components/creator-card/ImagesStack';
import Link from '@/components/link';
import styles from './CreatorCard.module.scss';

const CREATOR_OVERFLOW_MENU_STYLES = {
  container: styles.overflowMenu,
  button: styles.overflowMenuButton,
};

export type CreatorCardProps = {
  testID?: string;
  id: string;
  fullName: string;
  age?: string;
  location?: string;
  profileImage?: string;
  categories: string[];
  images: [string, string, string];
  // email is used when CreatorCardProps is passed in onClick event
  // eslint-disable-next-line react/no-unused-prop-types
  email: string;
  socialNetworks: SocialNetworkInfo[];
  selectedSocialNetworkProvider?: string;
  rating?: number;
  signupDate?: string;
  ctaButtonText?: string;
  onCtaButtonClick?: (creator: CreatorCardProps) => void;
  creatorProfileUrl?: string;
  isOverflowMenuEnabled?: boolean;
  overflowMenuItems?: CreatorMenuItemProps[];
  onSocialNetworkClick?: (networkProvider: string, creatorId: string) => void;
  onCardClick?: (creatorId: string) => void;
};

function CreatorCard(props: CreatorCardProps) {
  const {
    testID = 'creator-card',
    id,
    socialNetworks,
    selectedSocialNetworkProvider,
    images,
    profileImage,
    fullName,
    age,
    location,
    categories,
    rating,
    signupDate,
    ctaButtonText,
    onCtaButtonClick,
    creatorProfileUrl,
    isOverflowMenuEnabled,
    overflowMenuItems = [],
    onSocialNetworkClick,
    onCardClick,
  } = props;
  const leadPlatformInfo = socialNetworks.find((network) =>
    selectedSocialNetworkProvider
      ? network.provider.includes(selectedSocialNetworkProvider)
      : undefined
  );

  const creatorCardElement = (
    <Paper
      elevation={1}
      square={false}
      className={styles.container}
      data-testid={testID}
    >
      <CreatorOverflowMenuButton
        isEnabled={isOverflowMenuEnabled}
        classes={CREATOR_OVERFLOW_MENU_STYLES}
        creator={props}
        menuItems={overflowMenuItems}
      />
      <Badge rating={rating} signupDate={signupDate} />
      <ImagesStack images={images} />
      <CreatorAvatar profileImageUrl={profileImage} />
      <Stack direction="column" gap="24px" alignItems="center" marginTop="12px">
        <CreatorInfo fullName={fullName} age={age} location={location} />
        <CreatorCategories categories={categories} />
        <Stack direction="column" spacing="8px" alignItems="center">
          <CreatorSocialsIcons
            socialNetworks={socialNetworks}
            selectedPlatformProvider={selectedSocialNetworkProvider}
            onClickSocialNetwork={(networkProvider: string) =>
              onSocialNetworkClick?.(networkProvider, id)
            }
          />
          {leadPlatformInfo && (
            <CreatorSocialInfo leadPlatformInfo={leadPlatformInfo} />
          )}
        </Stack>
        {ctaButtonText && onCtaButtonClick && (
          <CreatorCardCta
            buttonText={ctaButtonText}
            onCtaButtonClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onCtaButtonClick(props);
            }}
          />
        )}
      </Stack>
    </Paper>
  );
  return creatorProfileUrl ? (
    <Link to={creatorProfileUrl} onClick={() => onCardClick?.(id)}>
      {creatorCardElement}
    </Link>
  ) : (
    creatorCardElement
  );
}

export default CreatorCard;
