import {partition} from 'lodash';
import React from 'react';
import {Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import CheckboxLabel from '@/views/creators/components/checkbox-label';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import styles from './ListDrawer.module.scss';
import {List, NewListButton, ListDrawerAccordion} from './components';
import useListProps from './hooks/useListProps';

const TRANSLATION_PREFIX = 'components.list-drawer';

export type ListDrawerProps = {
  testID?: string;
  labels: CreatorGroupLabel[];
};

function ListDrawer(props: ListDrawerProps) {
  const {testID = 'list-drawer', labels} = props;
  const brandId = useBrandId();
  const {currentBrand} = useAgencyQuery({brandId});
  const listProps = useListProps();

  const selectedLists = useListCreatorsStore((state) => state.selectedLists);
  const tableFilterValue = useListCreatorsStore(
    (state) => state.tableFilterValue
  );

  const allCreatorsSelected =
    selectedLists.size === 0 &&
    labels.length > 0 &&
    (tableFilterValue === null || tableFilterValue === '');

  const isSharedList = ({organizationLevel}: CreatorGroupLabel) =>
    organizationLevel === true;

  const [sharedLists, brandLists] = partition(labels, isSharedList);

  const defaultLabel = labels[0]?.id;

  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.stickyHeader}>
        <div className={styles.heading}>
          <Headline size="xs">
            {translate(`${TRANSLATION_PREFIX}.heading`)}
          </Headline>
          <CheckboxLabel
            label={translate(`${TRANSLATION_PREFIX}.all-creators`)}
            checked={allCreatorsSelected}
            disabled={defaultLabel === undefined}
            onChange={() => {
              if (allCreatorsSelected) {
                listCreatorsActions.selectList(defaultLabel);
              } else {
                listCreatorsActions.showAllCreators();
              }
            }}
          />
        </div>
        <NewListButton />
      </div>
      {sharedLists.length === 0 ? (
        brandLists?.map((group) => (
          <List key={group.id} {...listProps(group)} />
        ))
      ) : (
        <>
          <ListDrawerAccordion
            label={translate(`${TRANSLATION_PREFIX}.shared-lists`)}
            count={sharedLists.length}
          >
            {sharedLists?.map((group) => (
              <List key={group.id} {...listProps(group)} />
            ))}
          </ListDrawerAccordion>
          <div className={styles.divider} />
          <ListDrawerAccordion
            count={brandLists.length}
            label={translate(`${TRANSLATION_PREFIX}.brand-lists`, {
              brandName:
                currentBrand?.name ??
                translate(
                  `${TRANSLATION_PREFIX}.brand-lists-brand-name-fallback`
                ),
            })}
            preIconName="Actions-Lock-Line"
          >
            {brandLists?.map((group) => (
              <List key={group.id} {...listProps(group)} />
            ))}
          </ListDrawerAccordion>
        </>
      )}
    </div>
  );
}

export default ListDrawer;
