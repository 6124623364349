import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import useMembershipsAndLabels from '@/views/creators/lists/useMembershipsAndLabels';

function useUpdateMembershipLabels() {
  const {membershipQueryKey} = useMembershipsAndLabels();

  const key = [
    'creatorGroupsMemberships',
    ...Object.values(membershipQueryKey),
  ];

  return useMutation({
    mutationKey: ['creatorGroupsMemberships', 'update'],
    mutationFn: creatorGroupUpdaters.updateMembershipLabels,
    onMutate: async (data) => {
      await queryClient.cancelQueries({queryKey: ['creatorGroupsMemberships']});

      const previousData = await queryClient.getQueryData(key);

      queryClient.setQueryData(key, (old: any) => {
        return {
          ...old,
          data: produce(old.data, (draft: CreatorGroupMembership[]) => {
            const membership = draft.find(
              (user: CreatorGroupMembership) => user.id === data.membership.id
            );
            if (membership) {
              membership.labels = data.labels;
            }
          }),
        };
      });

      return {previousData};
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(key, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useUpdateMembershipLabels;
