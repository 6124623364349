import clsx from 'clsx';
import React from 'react';
import {Menu, MenuItem} from '@mui/material';
import {
  Button,
  Icon,
  Label,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import AvatarsStack from '@/views/creators/components/creators-actions-bar/AvatarsStack';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './List.module.scss';

const TRANSLATION_PREFIX = 'components.list-drawer';

type ListProps = {
  group?: Partial<CreatorGroupLabel>;
  isSelected?: boolean;
  onClick?: () => void;
  disableMenu?: boolean;
  testID?: string;
};

function List(props: ListProps) {
  const {
    group,
    isSelected = false,
    onClick,
    disableMenu,
    testID = 'list-drawer-list',
  } = props;
  const contextMenuButtonRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const buttonProps = {
    appearance: 'secondary' as const,
    size: 'medium' as const,
    color: designSystemToken('semantic.fg.neutral'),
  };

  const updateContextMenu = (value: boolean) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpen(value);
  };

  return (
    <>
      <button
        className={clsx(styles.container, isSelected && styles.selected)}
        type="button"
        onClick={onClick}
      >
        <div className={styles.contentLeft}>
          <AvatarsStack
            avatarUrls={group?.avatars ?? []}
            hideLabel
            count={group?.creatorCount}
          />
          <Label className={styles.label} size="md">
            {group?.name}
          </Label>
        </div>
        {!disableMenu && (
          <div ref={contextMenuButtonRef}>
            <Button
              component="div"
              className={styles.contextMenuButton}
              onClick={updateContextMenu(true)}
              icon={
                <Icon
                  name="Actions-More"
                  appearance="secondary"
                  size="medium"
                />
              }
              appearance="overlay"
              size="medium"
              mode="filled"
            />
          </div>
        )}
      </button>
      <Menu
        slotProps={{
          paper: {className: styles.menuPaper},
        }}
        className={styles.menuRoot}
        open={menuOpen}
        onClose={updateContextMenu(false)}
        anchorEl={contextMenuButtonRef.current}
      >
        <MenuItem
          sx={{display: 'flex', gap: 1}}
          onClick={() => {
            listCreatorsActions.setActiveListAction('edit-list', group?.id);
          }}
        >
          <Icon name="Navigation-Edit" {...buttonProps} />
          {translate(`${TRANSLATION_PREFIX}.list-menu.edit`)}
        </MenuItem>
        <MenuItem
          sx={{display: 'flex', gap: 1}}
          onClick={() => {
            listCreatorsActions.setActiveListAction('delete-list', group?.id);
          }}
        >
          <Icon name="Actions-Remove" {...buttonProps} />
          {translate(`${TRANSLATION_PREFIX}.list-menu.delete`)}
        </MenuItem>
        <MenuItem
          sx={{display: 'flex', gap: 1}}
          onClick={() => {
            listCreatorsActions.setActiveListAction('share-list', group?.id);
          }}
        >
          <Icon name="Actions-Share" {...buttonProps} />
          {translate(`${TRANSLATION_PREFIX}.list-menu.share`)}
        </MenuItem>
      </Menu>
    </>
  );
}

export default List;
