import React from 'react';
import {Route} from 'react-router-dom';
import NotFound from '@/views/not-found';
import UnderMaintenance from '@/views/under-maintenance';

function PublicAccessRoutes() {
  return [
    <Route key="not-found-route" path="/not-found" element={<NotFound />} />,
    <Route
      key="under-maintenance"
      path="/maintenance"
      element={<UnderMaintenance />}
    />,
  ];
}

export default PublicAccessRoutes;
