import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Box, Chip} from '@mui/material';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import ChangeListPopover from '../ChangeListPopover';
import styles from './ListsCell.module.scss';

function ListsCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  const [open, setOpen] = React.useState(false);
  const boxRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className={styles.listsCell}>
      <Box
        sx={{
          display: 'inline-flex',
          flexFlow: 'row wrap',
          gap: 1,
          alignItems: 'center',
          minHeight: 30,
          minWidth: 300,
        }}
        ref={boxRef}
        onClick={() => {
          setOpen(true);
        }}
      >
        {row.original.labels.map((label) => (
          <Chip
            key={label.id}
            label={
              <Label size="sm" sx={{fontWeight: 500}}>
                {label.name}
              </Label>
            }
            variant="outlined"
            sx={{
              height: '24px',
              borderColor: designSystemToken('semantic.bg.neutral-inverse'),
            }}
          />
        ))}
      </Box>
      <ChangeListPopover
        membership={row.original}
        anchorEl={boxRef.current}
        onClose={() => setOpen(false)}
        open={open}
      />
    </div>
  );
}

export default ListsCell;
