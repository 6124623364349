import React, {useState} from 'react';
import {TextField} from '@mui/material';
import {
  Avatar,
  designSystemToken,
  Headline,
  Body,
  Label,
  Button,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Link from '@/components/link';
import useStartConversation from '@/hooks/mutations/creators/use-start-conversation';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandId from '@/hooks/use-brand-id';
import {ActionProps} from './ActionProps';
import styles from './MessageCreator.module.scss';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.message-creator';

function MessageCreator(props: ActionProps) {
  const {creators, onSubmitSuccess, showSuccessBanner} = props;
  const brandId = useBrandId();
  const {currentBrand} = useAgencyQuery({brandId});
  const startConversation = useStartConversation();

  const [message, setMessage] = useState('');

  if (creators.length !== 1) {
    return null;
  }
  const creator = creators[0];
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };
  const sendMessage = async () => {
    await startConversation.mutateAsync({
      brandId,
      creatorId: creator.id,
      initialMessage: message,
    });

    closeNotification();
  };

  const closeNotification = () => {
    onSubmitSuccess();
    showSuccessBanner(
      translate(`${TRANSLATION_PREFIX}.banner-title`),
      translate(`${TRANSLATION_PREFIX}.banner-message`)
    );
  };

  return (
    <div className={styles.messagesContainer}>
      <div className={styles.creatorDetails}>
        <Avatar
          size="3xl"
          src={creator.avatar}
          type={creator.avatar ? 'picture' : 'placeholder'}
          variant="circular"
          data-testid="avatar"
          background={designSystemToken('semantic.bg.tertiary')}
        />
        <div className={styles.creatorInfo}>
          <Headline size="md">
            <Link
              to={`/search/profiles/${creator.id}?brandId=${brandId}`}
              target="_blank"
            >
              {creator.name}
            </Link>
          </Headline>
          <Body size="sm">
            {creator.age}
            {creator.location && `. ${creator.location}`}
          </Body>
        </div>
      </div>
      <div className={styles.messageSection}>
        <Body size="lg">{translate(`${TRANSLATION_PREFIX}.title`)}</Body>
        <TextField
          multiline
          rows={4}
          fullWidth
          placeholder={translate(`${TRANSLATION_PREFIX}.message-placeholder`)}
          variant="filled"
          className={styles.messageTextField}
          value={message}
          onChange={handleInputChange}
        />
        <div className={styles.offPlatformPaymentNotice}>
          <Label size="lg">
            {translate(`${TRANSLATION_PREFIX}.notice-label`)}
          </Label>
          <Body size="md">
            {translate(`${TRANSLATION_PREFIX}.off-platform-payment`)}
          </Body>
        </div>
        <div className={styles.footer}>
          <Body size="sm" className={styles.brandInfo}>
            <Avatar
              size="sm"
              src={currentBrand.displayAvatarUrl}
              type="picture"
              variant="circular"
              background={designSystemToken('semantic.bg.tertiary')}
            />
            {translate(`${TRANSLATION_PREFIX}.sending-as`, {
              brandName: currentBrand.name,
            })}
          </Body>
          <Button
            appearance="neutral"
            mode="filled"
            size="large"
            className={styles.sendButton}
            disabled={!message.trim()}
            onClick={sendMessage}
          >
            {translate(`${TRANSLATION_PREFIX}.send`)}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MessageCreator;
