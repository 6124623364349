import React from 'react';
import styles from './PoppaysLoader.module.scss';

export type PoppaysLoaderProps = {
  testID?: string;
  light?: boolean;
  absolute?: boolean;
};

function PoppaysLoader(props: PoppaysLoaderProps) {
  const {testID = 'poppays-loader', light, absolute} = props;

  return (
    <div
      className={`${styles.container} ${light ? styles.light : ''} ${
        absolute ? styles.absolute : ''
      }`}
      data-testid={testID}
    >
      <svg viewBox="0 0 40 40">
        <polyline
          className={`${styles.loaderBracket} ${styles.loaderBracketLeft}`}
          points="11.83 2.96 2.96 2.96 2.96 36.96 11.96 36.96"
        />
        <polyline
          className={`${styles.loaderBracket} ${styles.loaderBracketRight}`}
          points="28.08 2.96 36.96 2.96 36.96 24.96 27.96 24.96"
        />
      </svg>
    </div>
  );
}

export default PoppaysLoader;
