export enum PaymentTypeOption {
  NOT_ACCEPTABLE = 'not_acceptable',
  INVOICE = 'invoice',
  NOT_APPLICABLE = 'not_applicable',
  CREDIT_CARD = 'credit_card',
}

export type Plan = {
  id: string;
  description: string;
  griffinQuoteId: string | null;
  label: string;
  marketingFeatureList: string[];
  marketingFeatureListHeading: string;
  name: string;
  priceFootnote: string;
  createdAt: string;
  cost: number;
  paymentType: PaymentTypeOption;
  featureThresholds: {
    [key: string]: number;
    brandCount: number;
    dashboardUserCount: number;
    totalCampaignCount: number;
    activeCampaignCount: number;
    creatorsPerCampaign: number;
    collaborationCount: number;
    threadCount: number;
  };
  features: Feature[];
  isPromoted: boolean;
};
