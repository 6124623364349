import {PlatformId} from '@/types/models/search-creators/filterId';
import {
  CreatorSearchResult,
  SearchDisplayCreator,
} from '@/types/models/search-creators/searchCreators';
import {SocialNetworkInfo} from '@/types/socials';

function mapCreatorsForDisplay(
  creators: CreatorSearchResult[],
  selectedPlatform: PlatformId
): SearchDisplayCreator[] {
  if (!creators.length) {
    return [];
  }
  return creators.map((creator) => ({
    ...creator,
    followers: pickHighestValue(creator.socialNetworks, 'followersCount'),
    engagementRate: pickHighestValue(creator.socialNetworks, 'engagementRate'),
    selectedPlatform: selectedPlatform === 'all' ? undefined : selectedPlatform,
  }));
}

function pickHighestValue(
  socialNetworks: SocialNetworkInfo[],
  fieldName: keyof SocialNetworkInfo
): number | undefined {
  if (!socialNetworks.length) {
    return undefined;
  }
  return socialNetworks.reduce((acc, socialNetwork) => {
    const accValue = (acc[fieldName] as number | undefined) ?? 0;
    const currentValue = (socialNetwork[fieldName] as number | undefined) ?? 0;
    return currentValue > accValue ? socialNetwork : acc;
  })[fieldName] as number | undefined;
}

export default mapCreatorsForDisplay;
