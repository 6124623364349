import {Grid, useGrid} from '@virtual-grid/react';
import {throttle} from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  RefObject,
} from 'react';
import styles from './VirtualizedGrid.module.scss';

const loadingIcon = '/assets/svg/icon-loading-circle-black.svg';

export type VirtualizedGridProps<T> = {
  testID?: string;
  containerClassName?: string;
  data: T[];
  onEndReached?: () => void;
  renderItem: (item: T) => React.ReactNode;
  renderItemSkeleton?: (index: number) => React.ReactNode;
  gap?: {x: number; y: number};
  columns: number;
  itemSize: {width: number; height: number};
  isInitialLoading?: boolean;
  skeletonsAmount?: number;
  showLoader?: boolean;
  gridScrollRef?: RefObject<HTMLDivElement>;
  gridScrollTopKey?: string;
};

function VirtualizedGrid<T>(props: VirtualizedGridProps<T>) {
  const {
    testID = 'virtualized-grid',
    containerClassName,
    data,
    onEndReached,
    renderItem,
    renderItemSkeleton,
    gap,
    columns,
    itemSize,
    isInitialLoading,
    skeletonsAmount,
    showLoader,
    gridScrollRef,
    gridScrollTopKey = 'gridScrollTop',
  } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const newScrollRef = useRef<HTMLDivElement>(null);
  const scrollRef = gridScrollRef || newScrollRef;

  useEffect(() => {
    const scrollTop = sessionStorage.getItem(gridScrollTopKey);
    if (scrollTop) {
      // In order to prevent the scroll from jumping to the top to the given location:
      // hide the view until the scroll is set
      const timeout = setTimeout(() => {
        scrollRef.current?.scrollTo(0, Number(scrollTop));
        setIsInitialized(true);
      });
      return () => clearTimeout(timeout);
    }

    setIsInitialized(true);

    return () => {};
  }, []);

  const grid = useGrid({
    scrollRef,
    count:
      data.length +
      (!!isInitialLoading && !!skeletonsAmount && !!renderItemSkeleton
        ? skeletonsAmount
        : 0),

    gap,
    columns,
    size: itemSize,
    onLoadMore: onEndReached,
  });

  const handleOnScroll = useCallback((event?: React.UIEvent<HTMLElement>) => {
    if (event?.target && gridScrollTopKey) {
      const {scrollTop} = event.target as HTMLDivElement;
      sessionStorage.setItem(gridScrollTopKey, scrollTop.toString());
    }
  }, []);

  const handleRenderItem = (index: number) => {
    if (isInitialLoading && renderItemSkeleton) {
      return renderItemSkeleton(index);
    }
    return renderItem(data[index]);
  };

  return (
    <div
      ref={scrollRef}
      className={`${styles.container} ${containerClassName} ${
        isInitialized ? '' : styles.notInitialized
      }`}
      data-testid={testID}
      onScroll={throttle(handleOnScroll, 100)}
    >
      <Grid grid={grid}>{(index) => handleRenderItem(index)}</Grid>
      {showLoader && (
        <div className={styles.loadingIconContainer}>
          <div className={styles.loadingIcon}>
            <img src={loadingIcon} alt="loading circle icon" />
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualizedGrid;
