import {orderBy} from 'lodash';
import React, {useMemo, useState} from 'react';
import {Box, Stack, styled} from '@mui/material';
import {
  Button,
  Input,
  SwitchButton,
  Body,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import SubmitButtonContainer from '@/views/creators/components/creator-action/action/SubmitButtonContainer';
import useCreateCreatorGroupLabel from '@/hooks/mutations/use-create-creator-group-label';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useBrandId from '@/hooks/use-brand-id';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './AddList.module.scss';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.add-to-my-creators';

const NewListContent = styled('div')`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-root {
    padding: 12px 0 0 16px;
  }
  .MuiCheckbox-root {
    margin: 0;
  }
  .MuiFormHelperText-root {
    display: none;
  }
`;

function AddList() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [isSharedList, setIsSharedList] = useState(false);
  const brandId = useBrandId();
  const {currentBrand, brands, data} = useAgencyQuery({
    brandId,
  });

  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  const {labels} = useCreatorGroupsLabelsQuery({creatorGroupId});

  const createCreatorGroupLabel = useCreateCreatorGroupLabel(creatorGroupId);
  const getGroupTitle = (brandName: string, organizationLevel: boolean) => {
    return `${brandName ? `${brandName} ` : ''}${translate(
      `${TRANSLATION_PREFIX}.group-title-${
        organizationLevel ? 'shared' : 'restricted'
      }`
    )}`;
  };

  const mappedOptions = useMemo(
    () =>
      /* Sorts groups so that organization level is first and list labels by ascending order */
      orderBy(
        labels.map((label) => ({
          id: label.id,
          label: label.name,
          organizationLevel: !!label.organizationLevel,
          groupTitle: getGroupTitle(
            currentBrand.name,
            !!label.organizationLevel
          ),
        })),
        [(item) => Number(item.organizationLevel), 'label'],
        ['desc', 'asc']
      ),
    [labels]
  );

  const handleCreateNewList = async () => {
    if (creatorGroupId) {
      const {data: newLabelData} = await createCreatorGroupLabel.mutateAsync({
        creatorGroupId,
        name: newListName,
        organizationLevel: isSharedList,
      });
      setNewListName('');
      listCreatorsActions.setActiveListAction(null);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await handleCreateNewList();
    setIsSubmitting(false);
  };

  const renderContent = () => {
    return (
      <NewListContent>
        <Input
          autoFocus
          value={newListName}
          onInputChange={(value: string) => setNewListName(value)}
          clearIcon={false}
          sx={{'.MuiInputBase-root': {minHeight: 56}}}
        />
        <div className={styles.organizationVisibility}>
          <Body size="lg" className={styles.description}>
            {translate(`${TRANSLATION_PREFIX}.organization-visibility`)}
          </Body>
          <SwitchButton
            checked={isSharedList}
            onChange={() => setIsSharedList((prev) => !prev)}
            className={`${styles.switchButton} ${
              isSharedList ? styles.checked : ''
            }`}
          />
        </div>
      </NewListContent>
    );
  };

  const renderFooter = () => {
    return (
      <Box display="flex" gap={1}>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          disabled={!newListName || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {translate(`${TRANSLATION_PREFIX}.submit-button-save`)}
        </Button>
      </Box>
    );
  };

  return (
    <Stack sx={{width: '100%'}}>
      {renderContent()}
      <SubmitButtonContainer>{renderFooter()}</SubmitButtonContainer>
    </Stack>
  );
}

AddList.textAlignment = 'left' as const;
AddList.footerAlignment = 'right' as const;

export default AddList;
