import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useBrandId from '@/hooks/use-brand-id';

function useDeleteLabel() {
  const brandId = useBrandId();
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';

  return useMutation({
    mutationKey: ['creatorGroupsLabels', 'delete'],
    mutationFn: creatorGroupUpdaters.deleteLabel,
    onMutate: async (data) => {
      const previousData = await queryClient.getQueryData([
        'creatorGroupsLabels',
        creatorGroupId,
      ]);

      queryClient.setQueryData(
        ['creatorGroupsLabels', creatorGroupId],
        (old: any) => {
          return {
            ...old,
            data: old.data.filter(
              (_label: CreatorGroupLabel) => _label.id !== data
            ),
          };
        }
      );

      return {previousData};
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ['creatorGroupsLabels', creatorGroupId],
        context
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useDeleteLabel;
