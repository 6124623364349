import React, {useEffect, useState} from 'react';
import {TextField, Autocomplete, FormControl, Chip, Box} from '@mui/material';
import {
  Modal,
  Headline,
  Icon,
  Checkbox,
  Button,
  Body,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import emailValidator from '@/utils/validators/email';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import useMembershipsAndLabels from '@/views/creators/lists/useMembershipsAndLabels';
import useListCreatorsStore from '@/stores/list-creators/listCreatorsStore';
import styles from './InviteExternalCreatorsModal.module.scss';

type InviteExternalCreatorsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  listOptions?: CreatorGroupLabel[];
};

const TRANSLATION_PREFIX = 'components.invite-external-creators-modal';

const renderEmailTags = (value: readonly string[], getTagProps: any) =>
  value.map((option: string) => {
    const tagProps = getTagProps({index: option});
    return (
      <Chip
        key={option}
        variant="outlined"
        label={option}
        {...tagProps}
        className={styles.chip}
      />
    );
  });

const renderListTags = (value: CreatorGroupLabel[], getTagProps: any) =>
  value.map((option: CreatorGroupLabel) => {
    const {key, ...restTagProps} = getTagProps({index: option.id});
    return (
      <Chip
        key={option.id}
        label={option.name}
        {...restTagProps}
        deleteIcon={
          <Box sx={{display: 'flex'}}>
            <Icon
              size="medium"
              appearance="neutral"
              name="Actions-Close-Small"
            />
          </Box>
        }
        variant="outlined"
      />
    );
  });

function InviteExternalCreatorsModal(props: InviteExternalCreatorsModalProps) {
  const {isOpen, onClose, onSuccess, listOptions = []} = props;

  const {creatorGroupId} = useMembershipsAndLabels();
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [localSelectedLists, setLocalSelectedLists] = useState<Set<string>>(
    new Set()
  );

  const globalSelectedLists = useListCreatorsStore(
    (state) => state.selectedLists
  );

  useEffect(() => {
    if (isOpen) {
      setEmails([]);
      setInputValue('');
      setLocalSelectedLists(new Set(globalSelectedLists));
    }
  }, [isOpen, globalSelectedLists]);

  const handleEmailChange = (event: any, newEmails: string[]) => {
    setEmails(newEmails);
  };

  const handleInputChange = (event: any, newInputValue: string) => {
    if (newInputValue.includes(' ')) {
      const newEmails = newInputValue.split(' ').filter(Boolean);
      setEmails([...emails, ...newEmails]);
      setInputValue('');
    } else {
      setInputValue(newInputValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ' ') {
      event.preventDefault();
      const trimmedInputValue = inputValue.trim();
      if (trimmedInputValue && !emails.includes(trimmedInputValue)) {
        setEmails([...emails, trimmedInputValue]);
        setInputValue('');
      }
    }
  };

  const renderOptionLabel = (option: CreatorGroupLabel) => option.name;

  const selectedListLabels = listOptions.filter((label) =>
    localSelectedLists.has(label.id)
  );

  const disableInviteButton = selectedListLabels.length === 0;

  const inviteExternalCreators = async () => {
    const validEmails = emails.filter(emailValidator);
    const invitePromises = emails.map(async (email) => {
      if (!emailValidator(email)) return null;

      const response = await creatorGroupUpdaters.inviteCreator(
        creatorGroupId,
        email
      );

      return creatorGroupUpdaters.updateMembershipLabels({
        membership: response.data as CreatorGroupMembership,
        labels: selectedListLabels,
      });
    });

    await Promise.all(invitePromises);

    const message =
      validEmails.length === 1
        ? translate(`${TRANSLATION_PREFIX}.banner-title-single`)
        : translate(`${TRANSLATION_PREFIX}.banner-title-multiple`);

    onSuccess(message);
    onClose();
  };

  return (
    <Modal showCloseButton open={isOpen} size="large" handleClose={onClose}>
      <div className={styles.container}>
        <Headline size="md" className={styles.listSelectionHeader}>
          {translate(`${TRANSLATION_PREFIX}.title`)}
        </Headline>
        <Body size="lg" className={styles.description}>
          {translate(`${TRANSLATION_PREFIX}.description`)}
        </Body>
        <FormControl fullWidth className={styles.emailInputField}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={emails}
            onChange={handleEmailChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            renderTags={renderEmailTags}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                placeholder={
                  emails.length === 0
                    ? translate(`${TRANSLATION_PREFIX}.emails-placeholder`)
                    : ''
                }
                className={styles.textField}
              />
            )}
            clearIcon={false}
          />
        </FormControl>
        <Headline size="xs" className={styles.listSelectionHeader}>
          {translate(`${TRANSLATION_PREFIX}.choose-list`)}
        </Headline>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            options={listOptions}
            getOptionLabel={renderOptionLabel}
            value={selectedListLabels}
            onChange={(event, value) => {
              const newSelectedIds = new Set(value.map((v) => v.id));
              setLocalSelectedLists(newSelectedIds);
            }}
            clearIcon={false}
            className={styles.listSelect}
            classes={{
              focused: styles.focused,
              inputRoot: styles.inputRoot,
            }}
            renderTags={renderListTags}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(optionProps, option, {selected}) => (
              <li
                {...optionProps}
                className={`${optionProps.className} ${styles.listSelectionOption}`}
              >
                <Checkbox
                  checked={selected}
                  onChange={() => {}}
                  customCheckedIcon={
                    <img src="/assets/svg/checkbox-checked.svg" alt="" />
                  }
                  customUncheckedIcon={
                    <img src="/assets/svg/checkbox-unchecked.svg" alt="" />
                  }
                />
                {option.name}
              </li>
            )}
            disableCloseOnSelect
          />
        </FormControl>

        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          disabled={disableInviteButton}
          className={styles.inviteActionButton}
          onClick={inviteExternalCreators}
        >
          {translate(`${TRANSLATION_PREFIX}.invite`)}
        </Button>
      </div>
    </Modal>
  );
}

export default InviteExternalCreatorsModal;
