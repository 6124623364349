import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import useMembershipsAndLabels from '@/views/creators/lists/useMembershipsAndLabels';

function useUpdateMemberships() {
  const {membershipQueryKey} = useMembershipsAndLabels();
  const key = [
    'creatorGroupsMemberships',
    ...Object.values(membershipQueryKey),
  ];

  return useMutation({
    mutationKey: [membershipQueryKey],
    mutationFn: creatorGroupUpdaters.updateMemberships,
    onMutate: async (data) => {
      const previousData = await queryClient.getQueryData(key);

      queryClient.setQueryData(key, (old: any) => {
        return {
          ...old,
          data: produce(old.data, (draft: CreatorGroupMembership[]) => {
            data.memberships.forEach((updatedMembership) => {
              const index = draft.findIndex(
                (membership) => membership.id === updatedMembership.id
              );
              if (index !== -1) {
                draft[index] = updatedMembership;
              }
            });
          }),
        };
      });

      return {previousData};
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(key, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useUpdateMemberships;
