import {MRT_Row} from 'material-react-table';
import React from 'react';
import {designSystemToken, Tag} from '@lightricks/react-design-system';
import CreatorGroupMembership, {
  MembershipStatus,
} from '@/types/creatorGroupMembership';

function StatusCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  const {status} = row.original;

  const statusText: {[K in MembershipStatus]: string} = {
    removed: 'Removed',
    active: 'Active',
    deactivated: 'Deactivated',
    pending: 'Pending',
  };

  const statusBackgroundColor: {[K in MembershipStatus]: string} = {
    removed: designSystemToken('reference.red.10'),
    active: designSystemToken('reference.purple.10'),
    deactivated: designSystemToken('reference.gray.050'),
    pending: designSystemToken('reference.yellow.10'),
  };

  const statusTextColor: {[K in MembershipStatus]: string} = {
    removed: designSystemToken('reference.red.50'),
    active: designSystemToken('reference.purple.50'),
    deactivated: designSystemToken('reference.gray.500'),
    pending: designSystemToken('reference.yellow.50'),
  };

  return (
    <Tag
      text={statusText[status]}
      backgroundColor={statusBackgroundColor[status]}
      textColor={statusTextColor[status]}
    />
  );
}

export default StatusCell;
