import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import XHR from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translationEn from '../locales/en';

// initialization
i18n
  .use(XHR)
  .use(intervalPlural)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: ['en'], // use en if detected lng is not available
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}',
    },
    // translation files are added here
    resources: {
      en: {
        translations: translationEn,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
