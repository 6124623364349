import {
  VIEW_TYPE_GRID,
  VIEW_TYPE_TABLE,
} from '@/stores/search-creators/searchCreatorsStore';

export const FLOW_NAMES = {
  CAMPAIGN_CREATION: 'campaign_creation',
  AI_STUDIO: 'ai_studio',
  RECRUITMENT_SEARCH: 'recruitment_search',

  MY_CREATORS: {
    INVITE_TO_CAMPAIGN: 'invite_to_campaign',
  },
};

export const EVENT_NAMES = {
  CAMPAIGN_CREATION_INTERACTION: 'campaign_creation_interaction',
  CAMPAIGN_CREATION_PROCESS_STARTED: 'campaign_creation_process_started',
  CAMPAIGN_CREATION_PROCESS_ENDED: 'campaign_creation_process_ended',
  AI_STUDIO_ENTERED: 'ai_studio_entered',
  AI_STUDIO_INTERACTION: 'ai_studio_interaction',
  AI_STUDIO_ASSET_INTERACTION: 'ai_studio_asset_interaction',
  AI_STUDIO_ASSET_IMPORTED: 'ai_studio_asset_imported',
  AI_STUDIO_ASSET_PROCESS_STARTED: 'ai_studio_asset_process_started',
  AI_STUDIO_ASSET_PROCESS_ENDED: 'ai_studio_asset_process_ended',
};

export const SCREEN_NAMES = {
  SEARCH: 'Search',
  SEARCH_CREATORS: {
    [VIEW_TYPE_TABLE]: 'search-list',
    [VIEW_TYPE_GRID]: 'search-cards',
  },
};

export const SOURCE_NAMES = {
  MY_CREATORS: 'my_creators',
};
