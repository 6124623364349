import {uuidRegex} from '@/config/constants';

export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/not-found',
  ERROR: '/errors/(deactivated|session-expired|forbidden)',
  PUBLIC_PROFILE: `/p/${uuidRegex}`, // /p/:profileId
  PROFILE_SHARED_LIST: `/shared-list(/${uuidRegex})?`, // /shared-list/:profileId?type=(proposals|contracts|creator_groups|labels)&ref_id=:campaignId&filter=(is_pending|is_shortlisted|all)
  VIDEO_PREVIEWS: `/video_previews/${uuidRegex}`, // /video_previews/:contentSubmissionId
  PHOTO_PREVIEWS: `/photo_previews/${uuidRegex}`, // /photo_previews/:contentSubmissionId
  SIGN_UP_REACT: '/signup',
  SIGN_UP_REACT_OLD: '/sign-up',
  LOGIN: '/login',
  UPGRADE_SUBSCRIPTION: '/upgrade',
  PREFERENCES: '/preferences',
  ORGANIZATION_SETTINGS: '/organization-settings',
  AI_STUDIO_DASHBOARD: '/ai-studio/generated-content/dashboard',
  MOBILE_NOT_SUPPORTED: '/mobile-not-supported',
  PRICING: '/pricing',
  NEW_CAMPAIGN_BRIEF: `/new-campaigns/${uuidRegex}/brief/edit`, // /new-campaigns/:campaignId/brief/edit
  SEARCH: '/search',
  MY_CREATORS: '/creators',
};
export const UNAUTHENTICATED_ROUTES = [
  ROUTES.ERROR,
  ROUTES.PUBLIC_PROFILE,
  ROUTES.PROFILE_SHARED_LIST,
  ROUTES.VIDEO_PREVIEWS,
  ROUTES.PHOTO_PREVIEWS,
  ROUTES.SIGN_UP_REACT,
  ROUTES.SIGN_UP_REACT_OLD,
  ROUTES.LOGIN,
];
export const HIDE_BRAND_HEADER_ROUTES = [
  ...UNAUTHENTICATED_ROUTES,
  ROUTES.UPGRADE_SUBSCRIPTION,
  ROUTES.PRICING,
];

export const HIDE_MENU_ROUTES = [
  ...HIDE_BRAND_HEADER_ROUTES,
  ROUTES.AI_STUDIO_DASHBOARD,
  ROUTES.PRICING,
];

export const HIDE_SUBSCRIPTION_BANNER_ROUTES = [
  ...HIDE_BRAND_HEADER_ROUTES,
  ROUTES.AI_STUDIO_DASHBOARD,
];
