import {useEffect} from 'react';
import {
  CampaignFiltersStackResult,
  RangeFilterOptionsReturn,
  SingleSelectionFilterOptionsReturn,
} from '@/views/creators/search/utils/campaignValuesToSearchOptions';
import {searchCreatorsActions} from '@/stores/search-creators/searchCreatorsStore';

const useApplyCampaignFilters = (
  campaignFiltersStack: CampaignFiltersStackResult[],
  raiseSuccessCampaignFiltersSelected: () => void,
  isLoadingPrefetchedData: boolean
) => {
  useEffect(() => {
    if (isLoadingPrefetchedData) {
      return;
    }
    if (campaignFiltersStack.length > 0) {
      campaignFiltersStack.forEach((filter: CampaignFiltersStackResult) => {
        if (!filter) return;
        switch (filter.filterSelectionType) {
          case 'singleSelection':
            searchCreatorsActions.forceChangeFilterOptionSelection(
              filter.filterSelectionType,
              filter.filterGroupId,
              filter.filterId,
              (filter as SingleSelectionFilterOptionsReturn).option
            );
            break;
          case 'rangeSelection':
            searchCreatorsActions.forceChangeFilterRange(
              filter.filterGroupId,
              filter.filterId,
              (filter as RangeFilterOptionsReturn).range,
              filter.platformFilterId
            );
            break;
          default:
            break;
        }
      });
      searchCreatorsActions.applyFiltersFromFilterGroups();
      raiseSuccessCampaignFiltersSelected();
    }
  }, [campaignFiltersStack, isLoadingPrefetchedData]);
};

export default useApplyCampaignFilters;
