import React from 'react';
import {Modal} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import useListCreatorsStore, {
  listCreatorsActions,
  ListActionKey,
} from '@/stores/list-creators/listCreatorsStore';
import useMembershipsAndLabels from '../useMembershipsAndLabels';
import {AddList, EditList, DeleteList, ShareList} from './modals';

const TRANSLATION_PREFIX = 'components.list-action';

type ListActionProps = {
  label?: CreatorGroupLabel;
};

type ModalProps = React.ComponentProps<typeof Modal>;

function ListAction() {
  const {labels, isLoading} = useMembershipsAndLabels();
  const activeListId = useListCreatorsStore((state) => state.activeListId);
  const activeListAction = useListCreatorsStore(
    (state) => state.activeListAction
  );

  const label = labels.find(
    (_label: CreatorGroupLabel) => _label.id === activeListId
  );

  const componentMap: Partial<{
    [K in ListActionKey]: {
      Component: React.FC<ListActionProps>;
      header: string;
      textAlignment?: ModalProps['textAlignment'];
      footerAlignment?: ModalProps['footerAlignment'];
    };
  }> = {
    'add-list': {
      Component: AddList,
      header: translate(`${TRANSLATION_PREFIX}.add-list.header`),
      textAlignment: AddList.textAlignment,
      footerAlignment: AddList.footerAlignment,
    },
    'edit-list': {
      Component: EditList,
      header: translate(`${TRANSLATION_PREFIX}.edit-list.header`),
      textAlignment: EditList.textAlignment,
      footerAlignment: EditList.footerAlignment,
    },
    'delete-list': {
      Component: DeleteList,
      header: translate(`${TRANSLATION_PREFIX}.delete-list.header`, {
        label: label?.name,
      }),
    },
    'share-list': {
      Component: ShareList,
      header: translate(`${TRANSLATION_PREFIX}.share-list.header`),
      textAlignment: ShareList.textAlignment,
      footerAlignment: ShareList.footerAlignment,
    },
  };

  if (activeListAction === null || componentMap[activeListAction] === undefined)
    return null;

  const {Component, textAlignment, footerAlignment} =
    componentMap[activeListAction]!;

  return (
    <Modal
      showCloseButton
      size="large"
      open={!!activeListAction}
      textAlignment={textAlignment}
      footerAlignment={footerAlignment}
      header={componentMap[activeListAction]?.header}
      handleClose={() => {
        listCreatorsActions.setActiveListAction(null);
      }}
    >
      {Component ? <Component label={label} /> : ''}
    </Modal>
  );
}

export default ListAction;
