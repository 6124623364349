import React, {useState, useEffect} from 'react';
import {Popover, Box, TextField} from '@mui/material';
import {
  Body,
  Headline,
  Label,
  Button,
  Icon,
} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {SOURCE_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import getCreatorIdsForAnalytics from '@/views/creators/utils/getCreatorIdsForAnalytics';
import SegmentedControl from '@/components/segmented-control';
import useCreateVetting from '@/hooks/mutations/vetting/useCreateVetting';
import useBrandId from '@/hooks/use-brand-id';
import styles from './VetCreatorPopover.module.scss';

interface VetCreatorPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  membership: CreatorGroupMembership;
  onVettingSuccess: () => void;
  flowId: string;
  screenPresentationId: string;
}

const TRANSLATION_PREFIX = 'views.creators.components.vet-creator-popover';

function VetCreatorPopover(props: VetCreatorPopoverProps) {
  const {
    open,
    anchorEl,
    onClose,
    membership,
    onVettingSuccess,
    flowId,
    screenPresentationId,
  } = props;
  const [difficultyLevel, setDifficultyLevel] = useState<string | undefined>(
    undefined
  );
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<'bottom' | 'top'>(
    'bottom'
  );

  const {mutate: createVetting, isLoading, isError} = useCreateVetting();
  const brandId = useBrandId();

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      setAnchorPosition(
        spaceBelow < 300 && spaceAbove > spaceBelow ? 'top' : 'bottom'
      );
    }
  }, [anchorEl]);

  if (!anchorEl) return null;

  const handleSave = () => {
    if (difficultyLevel || feedback) {
      createVetting(
        {
          userId: membership.userId,
          merchantId: brandId,
          feedback,
          difficultyLevel: difficultyLevel
            ? parseInt(difficultyLevel, 10)
            : undefined,
        },
        {
          onSuccess: () => {
            onClose();
            setError(null);
            onVettingSuccess();

            const questionsAndAnswers = {
              [translate(`${TRANSLATION_PREFIX}.question-difficulty`)]:
                difficultyLevel ? parseInt(difficultyLevel, 10) : undefined,
              [translate(`${TRANSLATION_PREFIX}.question-feedback`)]: feedback,
            };

            const answerJson = JSON.stringify(questionsAndAnswers);

            AnalyticsService.dispatchEvent(eventNames.VETTING_SUBMIT, {
              flow_id: flowId,
              screen_presentation_id: screenPresentationId,
              creator_ids: getCreatorIdsForAnalytics([membership]),
              source: SOURCE_NAMES.MY_CREATORS,
              answer: answerJson,
            });
          },
        }
      );
    }
  };

  const segments = [
    {id: '1', label: '1'},
    {id: '2', label: '2'},
    {id: '3', label: '3'},
    {id: '4', label: '4'},
    {id: '5', label: '5'},
  ];

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: anchorPosition === 'bottom' ? 'bottom' : 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: anchorPosition === 'bottom' ? 'top' : 'bottom',
        horizontal: 'center',
      }}
      className={styles.popover}
    >
      <Button
        appearance="neutral"
        mode="plain"
        size="small"
        className={styles.closeButton}
        onClick={onClose}
        icon={
          <Icon size="medium" appearance="neutral" name="Actions-Close-Small" />
        }
      />
      <Box className={styles.container}>
        <Headline size="md" className={styles.title}>
          {translate(`${TRANSLATION_PREFIX}.header`)}
        </Headline>
        <div className={styles.difficultyLevel}>
          <SegmentedControl
            segments={segments}
            selectedSegmentId={difficultyLevel}
            onChange={(id) => setDifficultyLevel(id)}
            classes={{
              segment: styles.segment,
              activeSegment: styles.activeSegment,
              control: styles.segmentedControl,
            }}
          />
        </div>
        <div className={styles.segmentLabels}>
          <Body size="sm">
            {translate(`${TRANSLATION_PREFIX}.not-difficult`)}
          </Body>
          <Body size="sm">
            {translate(`${TRANSLATION_PREFIX}.very-challenging`)}
          </Body>
        </div>
        <Label size="lg">{translate(`${TRANSLATION_PREFIX}.feedback`)}</Label>
        <TextField
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          variant="filled"
          fullWidth
          margin="normal"
          className={styles.feedback}
          placeholder={translate(`${TRANSLATION_PREFIX}.feedback-placeholder`)}
        />
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          onClick={handleSave}
          className={styles.saveButton}
          disabled={!feedback && !difficultyLevel}
        >
          {translate(`${TRANSLATION_PREFIX}.submit`)}
        </Button>
      </Box>
    </Popover>
  );
}

export default VetCreatorPopover;
