import React, {useEffect} from 'react';
import {designSystemToken, Icon, Input} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ENTER_KEY} from '@/config/constants';
import styles from './SearchInput.module.scss';

const TRANSLATION_PREFIX = 'views.creators.components.search-input';

type SearchInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
};

function SearchInput(props: SearchInputProps) {
  const {value = '', onChange, onSubmit, disabled, placeholder} = props;
  const [inputValue, setInputValue] = React.useState(value);

  useEffect(() => {
    if (!value) {
      setInputValue('');
    } else if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const handleOnChange = (_value: string) => {
    const previousValue = inputValue;
    setInputValue(_value);
    onChange?.(_value);

    if (!_value && previousValue) {
      onSubmit(_value);
    }
  };

  return (
    <Input
      className={styles.searchInput}
      placeholder={
        placeholder ?? translate(`${TRANSLATION_PREFIX}.placeholder`)
      }
      value={inputValue}
      disabled={disabled}
      inputProps={{value: inputValue}}
      onInputChange={handleOnChange}
      clearIcon={!disabled}
      onKeyPress={({key, charCode}: {key: string; charCode: number}) => {
        if (key === ENTER_KEY.key || charCode === ENTER_KEY.charCode) {
          onSubmit(inputValue);
        }
      }}
      startIcon={
        <div
          className={`${styles.iconContainer} ${
            disabled ? styles.disabled : ''
          }`}
          onClick={() => onSubmit(inputValue)}
        >
          <Icon
            appearance="neutral"
            size="small"
            name="Actions-Search-Generic"
            color={
              disabled
                ? designSystemToken('semantic.fg.disabled')
                : designSystemToken('semantic.fg.inverse-secondary')
            }
          />
        </div>
      }
    />
  );
}

export default SearchInput;
