import React from 'react';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useCreatorGroupsMembershipsQuery from '@/hooks/queries/use-creator-groups-memberships-query';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore from '@/stores/list-creators/listCreatorsStore';

type PaginationMeta = {
  meta: {
    pagination: {
      currentPage: number;
      totalPages: number;
      totalEntries: number;
    };
  };
};

function useMembershipsAndLabels() {
  const brandId = useBrandId();
  const selectedLists = useListCreatorsStore((state) => state.selectedLists);
  const tableFilterValue = useListCreatorsStore(
    (state) => state.tableFilterValue
  );
  const membershipsPage = useListCreatorsStore(
    (state) => state.membershipsPage
  );
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  const membershipQueryKey = {
    creatorGroupId,
    labelIds: [...selectedLists],
    q: tableFilterValue,
    page: membershipsPage,
  };

  const response = useCreatorGroupsMembershipsQuery(membershipQueryKey);

  const {isLoading: isMembershipsLoading} = response;
  const memberships = (response.data as CreatorGroupMembership[]) ?? [];

  const [cachedTotalPages, setCachedTotalPages] = React.useState(1);
  const responseTotalPages = (response as typeof response & PaginationMeta).meta
    ?.pagination.totalPages;

  if (responseTotalPages && responseTotalPages !== cachedTotalPages) {
    setCachedTotalPages(responseTotalPages);
  }

  const {labels, isLoading: isLabelsLoading} = useCreatorGroupsLabelsQuery({
    creatorGroupId,
    refetchOnWindowFocus: false,
  });

  return {
    memberships,
    labels,
    isLoading: isLabelsLoading || isMembershipsLoading,
    membershipQueryKey,
    creatorGroupId,
    totalPages: cachedTotalPages,
  };
}

export default useMembershipsAndLabels;
