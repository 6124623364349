import {chain} from 'lodash';
import React, {useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {SOURCE_NAMES} from '@/lib/analytics/analyticsConstants';
import {buttonNames, screenNames} from '@/lib/delta/deltaConstants.js';
import calculateAge from '@/utils/calculateAge';
import translate from '@/utils/translate';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import CreatorAction, {
  CREATOR_ACTION_KEYS,
} from '@/views/creators/components/creator-action/CreatorAction';
import {ActionItem} from '@/views/creators/components/creators-actions-bar/ActionsBar';
import CreatorsActionsBar from '@/views/creators/components/creators-actions-bar/CreatorsActionsBar';
import getCreatorIdsForAnalytics from '@/views/creators/utils/getCreatorIdsForAnalytics';
import FloatingPopperBar from '@/components/floating-popper-bar';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

const TRANSLATION_PREFIX = 'views.creators';

function ListCreatorsActionBar() {
  const POPPER_HORIZONTAL_PADDING = 16;
  const POPPER_MAX_WIDTH = 1100;
  const POPPER_PLACEMENT_OFFSET = 24;

  const activeCreatorAction = useListCreatorsStore(
    (state) => state.activeCreatorAction
  );

  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  useEffect(() => {
    return () => {
      listCreatorsActions.deselectAllCreators();
    };
  }, []);

  const hasProfile = (membership: CreatorGroupMembership) =>
    membership.profile !== undefined;

  const selectedCreatorsProps = [...selectedCreators.values()]
    .filter(hasProfile)
    .map(({profile}) => ({
      id: profile.id,
      name: profile.displayName,
      avatar: profile.imageUrl,
      age: calculateAge(profile.birthDate),
      location: profile.location,
    }));

  const avatarUrls = React.useMemo(() => {
    return Array.from(selectedCreators.values())
      ?.filter(hasProfile)
      .map((creator: CreatorGroupMembership) => creator.profile.imageUrl);
  }, [selectedCreators]);

  // const actionInitState = React.useMemo(() => {
  //   return chain([...selectedCreators.values()])
  //     .map('labels')
  //     .flatten()
  //     .map(({id, organizationLevel, name: labelName}) => ({
  //       id,
  //       organizationLevel,
  //       label: labelName,
  //     }))
  //     .uniqBy('id')
  //     .value();
  // }, [selectedCreators]);

  const actionItems = useActionItems();

  const handleActionSubmit = (
    reason: 'success' | 'cancelled',
    actionName: string | null,
    creatorIds: string[]
  ) => {
    const creatorIdsObject = creatorIds.reduce(
      (acc: Record<string, string>, creatorId) => {
        acc[creatorId] = creatorId;
        return acc;
      },
      {}
    );
    if (reason === 'success') {
      const eventPayload = {
        screen_presentation_id: uuid(),
        action_name: actionName,
        creator_ids: creatorIdsObject,
        flow_name: actionName?.replace('-', ' ').toLowerCase(),
        campaign_id: '',
        reason: 'success',
        source: SOURCE_NAMES.MY_CREATORS,
        error: null,
      };
      AnalyticsService.dispatchEvent(
        eventNames.MY_CREATORS_ACTION_FLOW_ENDED,
        eventPayload
      );
    } else if (reason === 'cancelled') {
      const eventPayload = {
        screen_presentation_id: uuid(),
        action_name: actionName,
        creator_ids: creatorIdsObject,
        flow_name: actionName?.replace('-', ' ').toLowerCase(),
        campaign_id: '',
        reason: 'cancelled',
        source: SOURCE_NAMES.MY_CREATORS,
        error: null,
      };
      AnalyticsService.dispatchEvent(
        eventNames.MY_CREATORS_ACTION_FLOW_ENDED,
        eventPayload
      );
    }

    listCreatorsActions.setActiveCreatorAction(null);
  };

  return (
    <FloatingPopperBar
      isOpen={selectedCreators.size > 0}
      placement="bottom"
      widthCalculation={(width) =>
        width
          ? Math.min(width - POPPER_HORIZONTAL_PADDING * 2, POPPER_MAX_WIDTH)
          : 'auto'
      }
      placementMargin={POPPER_PLACEMENT_OFFSET}
      renderContent={() => (
        <CreatorsActionsBar
          avatarUrls={avatarUrls}
          actionItems={actionItems}
          closeButtonProps={{
            appearance: 'neutral',
            size: 'medium',
            mode: 'plain',
          }}
          closeButtonText="Clear selection"
          closeButtonOnClick={() => {
            listCreatorsActions.deselectAllCreators();

            AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
              button_name: buttonNames.MY_CREATORS.CLEAR_SELECTION,
              creator_id: selectedCreatorsProps.map((c) => c.id).join(','),
              screen_name: screenNames.MY_CREATORS.MY_CREATORS,
              flow_id: '',
              flow_name: '',
              triggered_flow_id: '',
              triggered_flow_name: '',
              screen_presentation_id: uuid(),
              tab: '',
              campaign_id: '',
            });
          }}
        />
      )}
    >
      <CreatorAction
        // initState={actionInitState}
        activeCreatorAction={activeCreatorAction}
        creators={selectedCreatorsProps}
        close={(reason) => {
          listCreatorsActions.setActiveCreatorAction(null);

          handleActionSubmit(
            reason,
            activeCreatorAction,
            selectedCreatorsProps.map((c) => c.id)
          );
        }}
      />
    </FloatingPopperBar>
  );
}

function useActionItems() {
  const {navigate} = useNavigation();
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const anyDeactivated = [...selectedCreators.values()].some(
    (membership) => membership.status === 'deactivated'
  );

  const conversationIds = [...selectedCreators.values()]
    .filter(
      (membership) =>
        membership?.profile?.currentBrandCollaborationConversation !== undefined
    )
    .map(({profile}) => profile.currentBrandCollaborationConversation.id);

  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const screenPresentationId = uuid();

  return React.useMemo<ActionItem[]>(() => {
    const actions: ActionItem[] = [
      {
        id: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
        icon: 'Navigation-Megaphone',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
        ),
        onClick: () => {
          listCreatorsActions.setActiveCreatorAction('add-to-campaign');

          AnalyticsService.dispatchEvent(
            eventNames.MY_CREATORS_ACTION_FLOW_STARTED,
            {
              screen_presentation_id: screenPresentationId,
              action_name: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
              creator_ids: getCreatorIdsForAnalytics([
                ...selectedCreators.values(),
              ]),
              flow_name: translate(
                `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
              ),
              campaign_id: '',
              source: SOURCE_NAMES.MY_CREATORS,
            }
          );
        },
        disabled: anyDeactivated,
      },

      {
        id: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
        icon: 'Navigation-Messages',
        tooltip:
          selectedCreators.size > 1
            ? translate(
                `${TRANSLATION_PREFIX}.components.creator-action.message-creator.bulk-tooltip`
              )
            : translate(
                `${TRANSLATION_PREFIX}.components.creator-action.message-creator.tooltip`
              ),
        onClick: () => {
          if (conversationIds.length > 0) {
            navigate(`/collaborations/${conversationIds[0]}`);
          } else {
            listCreatorsActions.setActiveCreatorAction('message-creator');
          }

          AnalyticsService.dispatchEvent(
            eventNames.MY_CREATORS_ACTION_FLOW_STARTED,
            {
              screen_presentation_id: screenPresentationId,
              action_name: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
              creator_ids: getCreatorIdsForAnalytics([
                ...selectedCreators.values(),
              ]),
              flow_name: translate(
                `${TRANSLATION_PREFIX}.components.creator-action.message-creator.tooltip`
              ),
              campaign_id: '',
              source: SOURCE_NAMES.MY_CREATORS,
            }
          );
        },
        disabled: selectedCreators.size !== 1 || anyDeactivated,
      },
      {
        id: CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS,
        icon: 'Actions-Remove',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.remove-creator.tooltip`
        ),
        onClick: () => {
          listCreatorsActions.setActiveCreatorAction('remove-from-my-creators');

          AnalyticsService.dispatchEvent(
            eventNames.MY_CREATORS_ACTION_FLOW_STARTED,
            {
              screen_presentation_id: screenPresentationId,
              action_name: CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS,
              creator_ids: getCreatorIdsForAnalytics([
                ...selectedCreators.values(),
              ]),
              flow_name: translate(
                `${TRANSLATION_PREFIX}.components.creator-action.remove-creator.tooltip`
              ),
              campaign_id: '',
              source: SOURCE_NAMES.MY_CREATORS,
            }
          );
        },
      },
    ];

    return subscription?.isCampaign
      ? actions.filter(
          (action) => action.id !== CREATOR_ACTION_KEYS.MESSAGE_CREATOR
        )
      : actions;
  }, [
    selectedCreators,
    anyDeactivated,
    conversationIds,
    navigate,
    subscription,
  ]);
}

export default ListCreatorsActionBar;
