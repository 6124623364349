import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import BrandElement from '@/components/brand-element';
import Link from '@/components/link';
import {MenuPopularPaysLogo} from '@/components/unusual-icons';
import UserMenu from '@/components/user-menu';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './BrandHeader.module.scss';
import useChildComponents from './useChildComponents';

export type BrandHeaderProps = {
  testID?: string;
  user: any;
  subscription: any;
};

function BrandHeader(props: BrandHeaderProps) {
  const isMobile = useIsMobile();
  const location = useLocation();

  const childComponents = useChildComponents();

  const {testID = 'brand-header', user, subscription} = props;

  const routeChildComponent = useMemo(() => {
    return childComponents.find((childComponent) =>
      new RegExp(childComponent.matcher, 'i').test(location.pathname)
    );
  }, [childComponents, location.pathname]);

  const childComponent = useMemo(() => {
    const Component = routeChildComponent?.component;

    return (
      <div
        data-testid={`${testID}--${Component?.name}`}
        className={styles.childComponentContainer}
      >
        {Component && <Component />}
      </div>
    );
  }, [testID, routeChildComponent]);

  const SecondaryComponent = useMemo(
    () => routeChildComponent?.secondaryComponent ?? null,
    [routeChildComponent]
  );

  const hideBrandElement = useMemo(
    () => Boolean(routeChildComponent?.hideBrandElement),
    [routeChildComponent]
  );

  const logoLinkOverride = useMemo(
    () => routeChildComponent?.logoLinkOverride,
    [routeChildComponent]
  );

  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.leftContainer}>
        <Link to={logoLinkOverride ?? '/'}>
          <MenuPopularPaysLogo height={32} />
        </Link>
        {isMobile ? null : childComponent}
      </div>
      <div className={styles.rightContainer}>
        {isMobile || hideBrandElement ? null : <BrandElement />}
        {SecondaryComponent && <SecondaryComponent />}
        {user.loaded && <UserMenu user={user} />}
      </div>
    </div>
  );
}

export default BrandHeader;
